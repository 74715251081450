import React, { useContext/*, useEffect*/ } from 'react';
import { SessionContext } from './SessionContext';

import styles from './ShowErrors.css';

export default () => {
	const context = useContext(SessionContext);

	return (
		context.errors && context.errors.length > 0 ?
			<div className={styles.ShowErrors + ' bg-danger text-white p-4'}>
				<div className="container m-auto position-relative">
					<ul style={{listStyle: 'none', fontSize: '1.1rem', margin: 0, padding: 0}}>{context.errors.map(ItemError)}</ul>
					
					<button onClick={context.resetErrors} type="button" className="close font-weight-lighter" aria-label="Close" style={{position: 'absolute', right: '15px', top: '-7px', color: 'white', fontSize: '2rem'}}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
			: null
	);
};

const ItemError = (error: any, index: number) => {
	const item = processError(error);

	return (
		item && <li key={index}>
			{item}
		</li>
	);
};

const processError = (error: any): string | null => {

	if (typeof(error) == 'string') {
		
		return error;

	} else if ('status' in error && 'json' in error && 'path' in error.json) {

		if (error.status == 401 && error.json.path == '/colo/profile') {
			return 'Veuillez entrer votre email pour continuer';
		} else if (error.status == 400 && error.json.errors) {
			let msg = '';
			for (let i = 0; i < error.json.errors.length; i++) {
				if (error.json.errors[i].defaultMessage) {
					msg += error.json.errors[i].defaultMessage + (error.json.errors[i].defaultMessage.endsWith('.') ? ' ' : '. ');
				}
			}

			if (msg) {
				return msg;
			}
		} else if ('message' in error.json) {
			return error.json.message;
		}

	} else if ('message' in error) {
		return error.message;
	}

	return JSON.stringify(error);
}