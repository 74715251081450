
function handleErrors(response: Response) {
	if (!response.ok) {
		throw response;
	}
	return response;
}

export default (url: string, options?: RequestInit): Promise<any> => {
	let rt: 'text' | 'json' = 'text';
	if (
		options &&
		options.headers &&
		options.headers['accept'] == APPLICATION_JSON
	) {
		rt = 'json';
	}
	
	return new Promise((resolve, reject) => {
		fetch(url, options)
			.then(handleErrors)
			.then(res =>
				rt == 'json' ? res.json() : /*rt == 'void' ? resolve() :*/ res.text()
			)
			.then(resolve)
			.catch(res => {
				if (rt == 'json' && res.json)
					res
						.json()
						.then((json: object) =>
							reject({ status: res.status, statusText: res.statusText, json })
						);
				else if (res.text)
					res
						.text()
						.then((text: string) =>
							reject({ status: res.status, statusText: res.statusText, text })
						);
				else
					reject({
						status: res.status,
						statusText: res.statusText,
						message: "Une erreur inconnue s'est produite",
						causedBy: res
					});
			});
	});
}

export const headers = (...headers: object[]) => {
	return { headers: Object.assign.apply({}, headers) };
};

export const APPLICATION_JSON = 'application/json;charset=UTF-8';

export const acceptJson = () => ({ accept: APPLICATION_JSON });
export const contentTypeJson = () => ({ 'content-type': APPLICATION_JSON });
export const tokenAuth = (token: string) => ({ authorization: 'Bearer ' + token });
