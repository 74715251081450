import React from 'react';
import { WizardPage } from '../../../utils/Wizard';
import TextField from '../Components/TextField';
import RadioBtns from '../Components/RadioBtns';
import { Title } from '../Components/WizardComponents';


export default ({values}: {values?: any}) => {
	const situation = values && values.situation || null;
	return (
		<WizardPage>
			<Title>{situation != 'SEUL' ? 'RESPONSABLES LEGAUX' : 'RESPONSABLE LEGAL'}</Title>

			<div className="form-row">
				<div className="form-group col-md-6">
					{situation != 'SEUL' && <Title h="3" className="pb-3">Premier Responsable légal</Title>}
					<ResponsablePart name="responsable1" />
				</div>

				{situation != 'SEUL' && <div className="form-group col-md-6">
					<Title h="3" className="pb-3">Deuxième Responsable légal</Title>
					<ResponsablePart name="responsable2" />
				</div>}
			</div>
		</WizardPage>
	);
}


export const ResponsablePart = ({ name }: {name: string}) => (
	<>
		<div className="form-row">
			<RadioBtns label="Civilité" name={name + '.sexe'} items={{MALE: 'Monsieur', FEMALE: 'Madame'}} colMd="12" inline />
		</div>
		<div className="form-row">
			<TextField label="Prénom" name={name + '.prenom'} colMd="12" />
		</div>
		<div className="form-row">
			<TextField label="Nom" name={name + '.nom'} colMd="12" />
		</div>

		<div className="form-row">
			<TextField label="Email" name={name + '.email'} readOnly={true} colMd="12" />
		</div>

		<div className="form-row">
			<TextField label="Téléphone" name={name + '.numeroTelephone'} colMd="12" />
		</div>
	</>
);
