import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Wizard, { OnSubmitWizardProps } from '../../utils/Wizard';
import ChildWizardPage from './WizardPages/ChildWizardPage';
import SejoursWizardPage from './WizardPages/SejoursWizardPage';
import RemarquesWizardPage from './WizardPages/RemarquesWizardPage';
import SelectionResponsableWizardPage from './WizardPages/SelectionResponsableWizardPage';
import SituationWizardPage from './WizardPages/SituationWizardPage';
import { ResponsableWizardPage } from './WizardPages/ResponsableWizardPage';
import { SessionContext } from '../SessionContext';
import { Enfant } from '../../api';
import AideFinanciereWizardPage from './WizardPages/AideFinanciereWizardPage';

interface ChildWizardProps extends OnSubmitWizardProps {
	enfant?: Enfant;
}

export default ({onSubmit, enfant}: ChildWizardProps) => {
	const context = useContext(SessionContext);

	if (!enfant) {
		let responsables: {[id: number]: string} = {};
		if (context.enfants) {
			context.enfants.forEach(e => {
				
				if (e.responsable1 && typeof(e.responsable1) != 'number' && context.user.id != e.responsable1.id) {
					responsables[e.responsable1.id] = e.responsable1.prenom + ' ' + e.responsable1.nom;
				}
				if (e.responsable2 && typeof(e.responsable2) != 'number' && context.user.id != e.responsable2.id) {
					responsables[e.responsable2.id] = e.responsable2.prenom + ' ' + e.responsable2.nom;
				}
			});
		}

		let defaultResponsable = null;
		let responsableIds: string[] = Object.keys(responsables);
		if (responsableIds.length == 0) {
			defaultResponsable = '';
		} else if (responsableIds.length == 1) {
			defaultResponsable = parseInt(responsableIds[0]);
		}
		// console.log(defaultResponsable, responsables);

		const submitHandler = (values: any) => 
			onSubmit(values).then((o) => {
				if (o == undefined)
					context.setMessage(<div className="position-absolute w-100 h-100 bg-white d-flex flex-column justify-content-center align-items-center" style={{zIndex: 1}}><h2 className="text-muted m-0 font-weight-normal">Votre enfant {values.prenom} a bien été inscrit</h2><div>Vous allez recevoir un email de confirmation</div><button className="btn btn-primary position-absolute" style={{marginTop: '10rem'}} onClick={() => context.setMessage()}>Voir le dossier</button></div>);
				
				return o;
			});
		return (
			<div className="container">
				
				<Wizard
					name="ChildWizard"
					onSubmit={submitHandler}
					initialValues={{responsable1: context.user!.id, responsable2: defaultResponsable}}
					cancelComponent={<Link to={context.rootUrl} className="btn btn-secondary" role="button">Annuler</Link>}
					showDebug={context.debugMode}>
					<SelectionResponsableWizardPage responsables={responsables} />
					<SituationWizardPage condition={(values: any) => typeof(values.responsable2) == 'object'} />
					<ResponsableWizardPage name="responsable2" condition={(values: any) => !!values.situation && values.situation != 'SEUL'} />
					<ChildWizardPage />
					<SejoursWizardPage />
					<RemarquesWizardPage />
					<AideFinanciereWizardPage />
				</Wizard>
				
			</div>
		);
	}

	const {responsable1, responsable2, ...rest} = enfant;
	
	const submitHandler = (values: any) => 
		onSubmit(values).then((o) => {
			if (o == undefined)
				context.setMessage(<div className="position-absolute w-100 h-100 bg-white d-flex flex-column justify-content-center align-items-center" style={{zIndex: 1}}><h2 className="text-muted m-0 font-weight-normal">Votre enfant {values.prenom} a bien été modifié</h2><button className="btn btn-primary position-absolute" style={{marginTop: '5rem'}} onClick={() => context.setMessage()}>Voir le dossier</button></div>);
			
			return o;
		});
	return (
		<div className="container">
			
			<Wizard
				name="ChildWizardLight"
				onSubmit={submitHandler}
				initialValues={rest}
				cancelComponent={<Link to={context.rootUrl + '/enfants/' + enfant.id} className="btn btn-secondary" role="button">Annuler</Link>}
				showDebug={context.debugMode}>
				<ChildWizardPage />
				{/* <RemarquesWizardPage /> */}
			</Wizard>
			
		</div>
	);
}
