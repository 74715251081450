import React, { useContext } from 'react';
import { SessionContext } from './SessionContext';
import FirstInscriptionWizard from './Wizards/FirstInscriptionWizard';
import { addEnfant } from '../api';
import { Switch, Route } from 'react-router-dom';
import { AccueilPage, AjouterEnfant, AjoutSejour, EnfantPage, ModifierEnfant, SejourPage } from './Pages';
import ShowErrors from './ShowErrors';
import ResponsablePage from './Pages/ResponsablePage';


export default () => {
	const context = useContext(SessionContext);
	
	return (
		<div className="InscriptionMain position-relative">
			{context.message}
			<ShowErrors />
			{context.loading
				? <div className="spinner-border" role="status">
					<span className="sr-only">Chargement...</span>
				</div>
				: !context.user.id
					? <FirstInscriptionWizard onSubmit={addEnfant(context.coloApiUrl, context.token, context.setEnfant, context.addError, context.updateResponsable)} />
					: <Switch>
						<Route path={context.rootUrl + '/ajouter-enfant'} component={AjouterEnfant} />
						<Route path={context.rootUrl + '/enfants/:enfantId/modifier'} component={ModifierEnfant} />
						<Route path={context.rootUrl + '/enfants/:enfantId/ajout-sejour'} component={AjoutSejour} />
						<Route path={context.rootUrl + '/enfants/:enfantId/sejours/:sejourId'} component={SejourPage} />
						<Route path={context.rootUrl + '/enfants/:enfantId'} component={EnfantPage} />
						<Route path={context.rootUrl + '/responsables'} component={ResponsablePage} />
						<Route path={context.rootUrl} component={AccueilPage} />
					</Switch>
			}
			
			{context.debugMode && <pre>{JSON.stringify(context, null, 2)}</pre>}
		</div>
	);
	
};

