import React from 'react';

interface CheckboxProps {
	name?: string,
	label: string,
	onChange?: (b: boolean) => void
	checked?: boolean
}

export default ({name, label, onChange, checked}: CheckboxProps) => {
	const id = (name || Math.random()) + '-checkbox';
	return (
		<div className="form-group custom-checkbox pl-3 ml-4">
			<input type="checkbox" className="custom-control-input" id={id} checked={checked} name={name} onChange={({target: {checked}}) => onChange && onChange(checked)} />
			<label className="custom-control-label" htmlFor={id}>{label}</label>
		</div>
	);
}