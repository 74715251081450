import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Wizard from '../../utils/Wizard';
import { SessionContext } from '../SessionContext';
import AideFinanciereWizardPage from './WizardPages/AideFinanciereWizardPage';
import SejoursWizardPage from './WizardPages/SejoursWizardPage';

interface VoyageWizardProps {
	onSubmit: (values: object) => any;

	enfantId: number
}

export default ({onSubmit, enfantId}: VoyageWizardProps) => {
	const context = useContext(SessionContext);
	const enfant = context.enfants!.find(e => e.id == enfantId)!;
	return (
		<div className="container">
			
			<Wizard
				name="SelectionSejourWizard"
				onSubmit={onSubmit}
				initialValues={{cafDepartement: enfant.cafDepartement, autresAides: enfant.autresAides}}
				cancelComponent={<Link to={context.rootUrl + '/enfants/' + enfantId} className="btn btn-secondary" role="button">Annuler</Link>}
				showDebug={context.debugMode}>
				
				<SejoursWizardPage filterAlreadySelectedForChildId={enfantId} />
				<AideFinanciereWizardPage />
				
			</Wizard>
			
		</div>
	);
};