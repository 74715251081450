import React, { useContext } from 'react';
import PageContext from './PageContext';


export const Title = ({children, h, className}: {children: any, h?: string | number, className?: string}) => {
	const context = useContext(PageContext);
	return React.createElement('h' + (h || '2'), {className: (className || 'pb-3 pt-5') + ' ' + context.textColor}, children);
}

export const Label = ({children, htmlFor}: {children: any, htmlFor?: string}) => {
	const context = useContext(PageContext);
	return <label htmlFor={htmlFor} className={'col-form-label pl-3 pr-3 mb-2 ' + context.labelClass}>{children}</label>;
}