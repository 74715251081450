import React, { useContext, useMemo } from 'react';
import { Enfant, SejourStatut } from '../../api';
import { SessionContext } from '../SessionContext';
import { Title } from '../Wizards/Components/WizardComponents';
import { Link } from 'react-router-dom';
import ResponsableInfo from '../ResponsableInfo';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useResponsables } from '../../utils/ResponsableUtils';
import { isDossierComplet } from '../../utils/DossierUtils';


export default () => {
	const context = useContext(SessionContext);
	const [responsable, responsable2] = useResponsables();

	const enfants = context.enfants;

	return (
		<>
			<div className="container-fluid bg-grey-light">
				<div className="container pb-5">
					<Title className="mb-0 pt-5 d-flex">
						<span>Responsable{responsable2 && 's'}</span>
						
						<Link className="btn btn-secondary ml-auto" to={context.rootUrl + '/responsables'}>Modifier <span className="fa fa-pencil pl-2"></span></Link>
						<button className="btn btn-primary ml-3" onClick={() => context.resetToken()}>Se déconnecter <span className="fa fa-unlock-alt pl-2"></span></button>
					</Title>
					
					<ResponsableInfo {...responsable} />
					{responsable2 && <ResponsableInfo {...responsable2} />}
				</div>
			</div>

			<div className="container-fluid bg-green3 white">
				<div className="container pb-1">
					<div className="row justify-content-between pb-3 pt-5">
						<Title className="m-0 text-white">Liste des enfants</Title>
						<Link to={context.rootUrl + '/ajouter-enfant'} className="btn btn-primary" role="button">Ajouter un enfant <span className="fa fa-plus pl-2"></span></Link>
					</div>
					
					{enfants && <ChildList enfants={enfants} /> || enfants == null && <div>Aucun enfant enregistré</div> || <div>Chargement...</div>}
				
				</div>
			</div>
		</>
	);
}


const ChildList = ({enfants}: {enfants: Enfant[]}) => 
	<table className="table table-hover text-white">
		<thead>
			<tr>
				<th scope="col">Genre</th>
				<th scope="col">Prénom</th>
				<th scope="col">Nom</th>
				<th scope="col">Date de naissance</th>
				<th scope="col">Lieu de naissance</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			{enfants.map(e => <ChildLine key={e.id} {...e} />)}
		</tbody>
	</table>;


const ChildLine = (e: Enfant) => {
	const history = useHistory();
	const { rootUrl, camps, documentTypes } = useContext(SessionContext);

	const sejourAlert = useMemo(() => e.sejours.filter(s => s.statut == SejourStatut.ACCEPTE && !isDossierComplet(s, camps, documentTypes)), [e.sejours, camps, documentTypes]);
	
	return (
		<tr onClick={() => history.push(rootUrl + '/enfants/' + e.id)} style={{cursor: 'pointer'}}>
			<td>{e.sexe == 'MALE' ? 'Garçon' : 'Fille'}</td>
			<td>{e.prenom}</td>
			<td>{e.nom}</td>
			<td>{moment(e.dateDeNaissance).format('L')}</td>
			<td>{e.lieuDeNaissance}</td>
			<td className="text-right">
				{sejourAlert && sejourAlert.length > 0 && 
				<span className="alert alert-warning mr-3" style={{fontSize: '.875rem', padding: '6px 14px'}}>
					<span className="fa fa-exclamation-triangle mr-1"></span> Dossier à compléter</span>}
				<span className="fa fa-angle-right"></span>
			</td>
		</tr>
	);
}

