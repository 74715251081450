import React from 'react';



export const ValueWithLabel = ({name, value}: {name: string, value: string}) => 
	<div className="d-inline-flex flex-fill flex-column mx-4 my-2">
		<span className="h6 m-0" style={{opacity: '0.5'}}>{name}</span>
		<span className="h5 m-0">{value}</span>
	</div>;

export const ValuesWithLabels = ({children}: {children: any[]}) => 
	<div className="d-flex">
		{children}
	</div>