import { useContext, useMemo } from "react";
import { Responsable } from "../api";
import { SessionContext } from "../InscriptionPart/SessionContext";

export const useResponsables = (): [Responsable, Responsable | undefined] => {
	const { enfants, user } = useContext(SessionContext);

	return useMemo(() => {
		let responsable2: Responsable | undefined = undefined;
		if (enfants) {
			let responsablesRef: {[k: number]: Responsable} = {};
			enfants.forEach(e => {
				if (e.responsable1 && typeof(e.responsable1) != 'number' && user.id != e.responsable1.id) {
					responsablesRef[e.responsable1.id] = e.responsable1;
				}
				if (e.responsable2 && typeof(e.responsable2) != 'number' && user.id != e.responsable2.id) {
					responsablesRef[e.responsable2.id] = e.responsable2;
				}
			});

			const responsablesId: number[] = Object.keys(responsablesRef).map(parseInt);
			if (responsablesId.length == 1) {
				responsable2 = responsablesRef[responsablesId[0]];
			}
		}
		return [user, responsable2];
	}, [enfants, user]);
}