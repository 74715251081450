import React from 'react';
import { WizardPage } from '../../../utils/Wizard';
import { Title } from '../Components/WizardComponents';
import TextField from '../Components/TextField';

export default ({title}: {title?: string}) => 
	<WizardPage>
		<Title>{title || 'REMARQUES'}</Title>

		<div className="form-row">
			<TextField label="Remarque(s)" name="remarques" colMd="12" component="textarea" required={false} />
		</div>
	</WizardPage>;