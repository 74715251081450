import React from 'react';

import { ValuesWithLabels, ValueWithLabel } from './ValueWithLabel';
import { Enfant, Sexe } from '../api';
import moment from 'moment';

export default (r: Enfant) => 
	<ValuesWithLabels>
		<ValueWithLabel name="Genre" value={r.sexe == Sexe.MALE ? 'Garçon' : 'Fille'} />
		<ValueWithLabel name="Nom" value={r.nom} />
		<ValueWithLabel name="Prénom" value={r.prenom} />
		<ValueWithLabel name="Date de naissance" value={moment(r.dateDeNaissance).format('L')} />
		<ValueWithLabel name="Lieu de naissance" value={r.lieuDeNaissance} />
		<ValueWithLabel name="Code INSEE" value={r.inseeCode} />
		{/* <ValueWithLabel name="Remarque" value={r.remarques} /> */}
		
	</ValuesWithLabels>;

