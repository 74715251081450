import send, { acceptJson, headers, tokenAuth } from '../utils/Http';
import { Sejour } from './sejours';

export interface DocumentType {
	code: string
	libelle: string
	multipage: boolean
	obligatoire: boolean
}

export const getDocumentTypes = (coloApiUrl: string, token: string): Promise<DocumentType[]> =>
	send(coloApiUrl + 'documents/types', headers(acceptJson(), tokenAuth(token)));


export const uploadDocument = (coloApiUrl: string, token: string, file: File, sejourId: number, type: string): Promise<Sejour> => {
	let data = new FormData();
	data.append('file', file);

	return send(
		coloApiUrl + 'sejours/' + sejourId + '/document/' + type,
		{
			method: 'POST',
			...headers(acceptJson(), tokenAuth(token)),
			body: data
		}
	)
}

export const deleteDocument = (coloApiUrl: string, token: string, documentId: number): Promise<void> => 
	send(coloApiUrl + 'documents/' + documentId, {method: 'DELETE', ...headers(tokenAuth(token))})
