import React, { useCallback, useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { Camp, Enfant, SensVoyage, updateVoyage } from '../../../api';
import { isInfoVoyageCompleted } from '../../../utils/DossierUtils';
import { SessionContext } from '../../SessionContext';
import RadioBtns from '../../Wizards/Components/RadioBtns';
import TextField from '../../Wizards/Components/TextField';


interface InfoVoyageProps {
	enfant: Enfant
	sejourId: number
	sejourIndex: number,
	def: Required<Camp>['voyageInfoDef']
}

export default ({sejourId, sejourIndex, enfant, def}: InfoVoyageProps) => {
	const context = useContext(SessionContext);

	const [error, setError] = useState<string>();

	const onSubmitHandler = useCallback((value: any): Promise<void> => 
		new Promise((resolve) => 
			updateVoyage(context.coloApiUrl, context.token, sejourId, value)
				.then(s => {
					enfant.sejours[sejourIndex] = s;
					context.setEnfant(enfant);
					//history.push(context.rootUrl + '/enfants/' + enfant.id);
					resolve();
				})
				.catch(err => {
					context.addError(err);
					if (typeof(err) == 'object' && 'json' in err && typeof(err.json) == 'object' && 'message' in err.json) {
						setError(err.json.message);
					}
					resolve();
				})
			),
		[context.coloApiUrl, context.token, sejourId, sejourIndex, enfant]
	);

	const isInfoCompeted = isInfoVoyageCompleted(enfant.sejours[sejourIndex]);
	const initialValues = {
		voyageAller: enfant.sejours[sejourIndex].voyageAller,
		voyageAllerInfo: enfant.sejours[sejourIndex].voyageAllerInfo,
		voyageRetour: enfant.sejours[sejourIndex].voyageRetour,
		voyageRetourInfo: enfant.sejours[sejourIndex].voyageRetourInfo
	};
	return (
		<div className="mt-4 mb-7">
			<h5>
				Informations concernant les voyages
				{!isInfoCompeted && <span className="alert alert-warning ml-3" style={{fontSize: '.875rem', padding: '6px 14px'}}>
					<span className="fa fa-exclamation-triangle mr-1"></span>
					Section à compléter
				</span>}
			</h5>
			<Form onSubmit={onSubmitHandler} initialValues={initialValues}>
				{form => (
					<form onSubmit={form.handleSubmit} autoComplete="off">
					
						<div className="form-row">
							<VoyageFields sens={SensVoyage.ALLER} def={def.ALLER} />
							<VoyageFields sens={SensVoyage.RETOUR} def={def.RETOUR} />
						</div>

						<div className="d-flex align-items-center">
							{!form.pristine && <button className="btn btn-primary" type="submit" disabled={form.invalid || form.submitting || form.validating}>Sauvegarder</button>}
							<span className="pl-2 text-danger">{error}</span>
							{form.submitting && <div className="position-relative pl-2" style={{height: '2rem'}}>
								<div className="spinner-border text-primary position-absolute" role="status">
									<span className="sr-only">Chargement...</span>
								</div>
							</div>}
						</div>
					</form>
				)}
			</Form>
		</div>
	);
};



interface VoyageWizardPageProps {
	sens: SensVoyage,
	def: {[k: string]: string}
}
const VoyageFields = ({sens, def}: VoyageWizardPageProps) =>
	<div className="form-group col-md-6">
		<RadioBtns label={sens} name={'voyage' + sens} items={def} colMd="12" />
		<TextField label="Informations complementaires" name={'voyage' + sens + 'Info'} required={false} colMd="12" />
	</div>;