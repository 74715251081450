import { FORM_ERROR } from 'final-form';


export type APIResponse<T, K extends string> = {
	[k in K]: T
} & {
	status: 200 | 401 | 403 | 500
	messsage: string
}

// -- Errors
interface FormError {
	errors: FieldError[];
}

interface FieldError {
	field: string
	code: string;
	defaultMessage: string;
}


export interface ApiErrors {
	[k: string]: any
}

export const processApiErrors = (err?: {json: FormError} | Object): ApiErrors | undefined => {
	let errors = undefined;
	if (err) {
		if (err && 'json' in err && 'errors' in err.json && err.json.errors.length > 0) {
			errors = {}
			for (let i = 0; i < err.json.errors.length; i++) {
				errors[err.json.errors[i].field] = err.json.errors[i].defaultMessage;
			}
		} else {
			errors = {[FORM_ERROR]: err};
		}
	}
	return errors;
}

