import React, { useCallback, useContext, useMemo } from 'react';
import { SessionContext } from '../SessionContext';
import { Title } from '../Wizards/Components/WizardComponents';
import { Sejour, Enfant, SejourStatut } from '../../api';
import { Link, useHistory, RouteComponentProps } from 'react-router-dom';
import EnfantInfo from '../EnfantInfo';
import BetweenDetail from '../../utils/BetweenDetail';
import { EnfantParam, UCFirst } from '../../utils';
import { SejourStatutLibelles } from '../../Libelles';
import { Message } from '../Message';
import { isDossierComplet } from '../../utils/DossierUtils';


export default ({match, history}: RouteComponentProps<EnfantParam>) => {
	const context = useContext(SessionContext);

	const id = parseInt(match.params.enfantId);
	const enfant = context.enfants!.find(e => e.id == id)!;

	const clickOnDeleteEnfant = useCallback(() => {
		context.setMessage(<SuppressionEnfantConfirm enfant={enfant} />);
	}, [enfant]);
	
	return (
		<div className="container-fluid bg-green3 text-white">
			<div className="container pb-3 pt-5">
				<div className="d-flex justify-content-between pb-3 pt-5">
					<Link to={context.rootUrl} className="btn px-3 mr-3 text-white" role="button"><span className="fa fa-angle-left"></span></Link>
					<Title className="m-0 text-white flex-fill">Enfant</Title>
					<button className="btn btn-secondary ml-3" onClick={() => history.push(context.rootUrl + '/enfants/' + enfant.id + '/modifier')}>Modifier <span className="fa fa-pencil pl-2"></span></button>
					{enfant.sejours.length == 0 && <button className="btn btn-primary ml-3" onClick={clickOnDeleteEnfant}>Supprimer <span className="fa fa-times pl-2"></span></button>}
				</div>
				
				<EnfantInfo {...enfant} />
				<div className="pt-4 pb-6">
					<Title h="3" className="m-0 text-white d-flex pb-1">
						<span className="flex-fill">Camps choisis</span>
						<button className="btn btn-primary" onClick={() => history.push(context.rootUrl + '/enfants/' + enfant.id + '/ajout-sejour')}>Ajouter un v{'\u0153'}u <span className="fa fa-plus pl-2"></span></button>
					</Title>

					<table className="table table-hover text-white">
						<thead>
							<tr>
								<th scope="col">Nom</th>
								<th scope="col">Date</th>
								<th scope="col">Statut</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{enfant.sejours.sort(SejourSorter).filter(s => ~SejourStatutOrder.indexOf(s.statut)).map(s => <SejourSelected key={s.id} sejour={s} enfant={enfant} />)}
						</tbody>
					</table>
				</div>
				 
			</div>
		</div>
	);
}

const SuppressionEnfantConfirm = ({enfant}: {enfant: Enfant}) => {
	const { setMessage, rootUrl, removeEnfant } = useContext(SessionContext);
	const { push } = useHistory();
	return (
		<Message
			title="Confirmez la suppression du dossier"
			buttons={<>
				<button className="btn btn-secondary mr-1" onClick={() => setMessage()}>Annuler</button>
				<button className="btn btn-primary ml-1" onClick={() => { removeEnfant(enfant); push(rootUrl); setMessage(); }}>Confirmer</button>
			</>}
		>
			Voulez vous vraiment supprimer  le dossier de {enfant.prenom} ?
		</Message>
	)
}

const SejourStatutOrder = [SejourStatut.VALIDE, SejourStatut.ACCEPTE, SejourStatut.SOUHAITE];
const SejourSorter = (s1: Sejour, s2: Sejour) => {
	const o1 = SejourStatutOrder.indexOf(s1.statut);
	const o2 = SejourStatutOrder.indexOf(s2.statut);
	
	let c = o1 - o2;
	if (!c) c = s1.ordreSouhait - s2.ordreSouhait
	return c;
};


const SejourSelected = ({sejour, enfant}: {sejour: Sejour, enfant: Enfant}) => {
	const history = useHistory();
	const context = useContext(SessionContext);
	const camp = context.camps[sejour.camp];

	const dossierComplet = useMemo(() => isDossierComplet(sejour, context.camps, context.documentTypes), [sejour, context.camps, context.documentTypes]);

	return (
		<tr style={{cursor: 'pointer'}} onClick={() => history.push(context.rootUrl + '/enfants/' + enfant.id + '/sejours/' + sejour.id)}>
			<td>{camp.libelle}</td>
			<td><BetweenDetail begin={camp.dateDebut} end={camp.dateFin} /></td>
			<td>{sejour.statut == SejourStatut.SOUHAITE ? 'V\u0153u ' + (sejour.ordreSouhait + 1) : UCFirst(SejourStatutLibelles[sejour.statut])}</td>
			<td className="text-right">
				{sejour.statut == SejourStatut.ACCEPTE && !dossierComplet && 
				<span className="alert alert-warning mr-3" style={{fontSize: '.875rem', padding: '6px 14px'}}>
					<span className="fa fa-exclamation-triangle mr-1"></span> Dossier à compléter</span>}
				<span className="fa fa-angle-right"></span>
			</td>
		</tr>
	);
}