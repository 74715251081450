import React, { useContext, useMemo } from "react";
import { Document, Sejour, SejourStatut } from "../../../api";
import { isAllDocumentNecessaryUploaded } from "../../../utils/DossierUtils";
import { SessionContext } from "../../SessionContext";
import { DocumentItem } from "./DocumentItem";

// camp.documentAttendus
// GET /documents/types pour les libellés

export const DocumentsRequired = ({sejour}: {sejour: Sejour}) => {

	const { camps, documentTypes } = useContext(SessionContext);

	const allDocIsUploaded = useMemo(() => isAllDocumentNecessaryUploaded(sejour, camps, documentTypes), [sejour, camps, documentTypes]);

	// A afficher que sur le status "accepté"
	// status "valide" -> on affiche les documents. Ceux validé sont "vérrouillé"
	if (sejour.statut != SejourStatut.ACCEPTE && sejour.statut != SejourStatut.VALIDE) {
		return null;
	}

	return (
		<div className="mt-4 mb-7">
			<h5>
				Documents à fournir
				{!allDocIsUploaded && <span className="alert alert-warning ml-3" style={{fontSize: '.875rem', padding: '6px 14px'}}>
					<span className="fa fa-exclamation-triangle mr-1"></span>
					Section à compléter
				</span>}
			</h5>
			<div className="text-muted">
				* Documents obligatoires
			</div>
			<div className="container">
				{camps[sejour.camp].documentAttendus.map(da => <DocumentTypeList key={da} type={da} sejour={sejour} locked={sejour.statut == SejourStatut.VALIDE} />)}

				<UnknownDocuments sejour={sejour} />
			</div>
		</div>
	);
}

const DocumentTypeList = ({type, sejour, disabled = false, locked}: {type: string, sejour: Sejour, disabled?: boolean, locked?: boolean}) => {
	const { documentTypes } = useContext(SessionContext);
	const documents = useMemo(() => {
		let filtered = sejour.documents?.filter(d => d.type == type).sort((p1, p2) => p1.id - p2.id) || [];
		let final: (Document | undefined)[] = filtered;
		if (!disabled && (type in documentTypes && documentTypes[type].multipage || final.length == 0)) {
			final.push(undefined);
		}
		return final;
	}, [type, sejour.documents]);

	return (
		<>
			{documents.map((sd, i) => <div key={type + '-' + i} className="row align-items-center border-top">
				<div className="col-4">
					{type in documentTypes ? documentTypes[type].libelle + (documentTypes[type].obligatoire ? '*' : '') : type}
				</div>
				<DocumentItem value={sd} sejour={sejour} type={type} index={i} disable={disabled} locked={locked} />
			</div>)}
		</>
	)
}


const UnknownDocuments = ({sejour}: {sejour: Sejour}) => {
	const { camps } = useContext(SessionContext);
	const unknownTypes = useMemo(() => sejour.documents?.map(d => d.type).filter(t => !camps[sejour.camp].documentAttendus.includes(t)) || []
	, [sejour.documents, camps, sejour.camp]);

	if (unknownTypes.length == 0) {
		return null;
	}

	return (
		<div>
			Non requis :
			{unknownTypes.map((type, i) => 
				<DocumentTypeList key={i} type={type} sejour={sejour} disabled />
			)}
		</div>
	)
}