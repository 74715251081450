import React from 'react';
import { Field } from 'react-final-form';
import { Label } from './WizardComponents';


export default ({label, name, items, inline, colMd, onChange, format, parse}: {label: string, name: string, items: object, inline?: boolean, colMd: string, onChange?: any, format?: (value: any, name: string) => any, parse?: (value: any, name: string) => any}) =>
	<div className={'form-group col-md-' + (colMd || 6)}>
		<Label>
			{label}
		</Label>
		<div className="pt-2">
			{Object.entries(items).map(([itemValue, itemLabel]) =>
				<div key={itemValue} className={'custom-control custom-radio' + (inline ? ' custom-control-inline' : '')}>
					<Field
						name={name}
						id={name + '-' + itemValue + '-field'}
						className="custom-control-input"
						component="input"
						type="radio"
						value={itemValue}
						required
						format={format}
						parse={parse}
						onClick={() => onChange && onChange(itemValue)}
					/>
					<label className="custom-control-label" htmlFor={name + '-' + itemValue + '-field'}>
						{itemLabel}
					</label>
				</div>
			)}
		</div>
	</div>;