import React from 'react';
import RadioBtns from './RadioBtns';
import TextField from './TextField';

export default () => 
	<>
		<div className="form-row">
			<RadioBtns label="Civilité" name="sexe" items={{MALE: 'Garçon', FEMALE: 'Fille'}} colMd="2" inline />
			
			<TextField label="Prénom" name="prenom" colMd="4" />
			<TextField label="Nom" name="nom" colMd="6" />
		</div>

		<div className="form-row">
			<TextField label="Date de naissance" name="dateDeNaissance" type="date" format={(value: string) => value ? value.substr(0, 10) : ''} />
			<TextField label="Lieu de naissance" name="lieuDeNaissance" />
		</div>

		<div className="form-row">
			<TextField label="Numéro de sécurité sociale dont dépend l'enfant" name="inseeCode" />
		</div>
	</>;