import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { deleteDocument, Document, Sejour, uploadDocument } from '../../../api'
import { Message } from '../../Message'
import { SessionContext } from '../../SessionContext'


export const Status = {
	EN_ATTENTE: <span className="text-warning"><span className="fa fa-hourglass-half" style={{fontSize: 20, paddingRight: 13, paddingLeft: 15}}></span> En attente de validation</span>,
	VALIDE: <span className="text-success"><span className="fa fa-check-circle" style={{fontSize: 20, paddingRight: 13, paddingLeft: 15}}></span> Valide</span>,
	INVALIDE: <span className="text-danger"><span className="fa fa-times-circle" style={{fontSize: 20, paddingRight: 13, paddingLeft: 15}}></span> Non valide</span>
}

export const DocumentItem = ({value, type, sejour, disable = false, index, locked = false}: {value?: Document, type: string, sejour: Sejour, disable?: boolean, index: number, locked?: boolean}) => {
	const { coloApiUrl, token, refreshData, documentTypes, updateSejour, setMessage, addError } = useContext(SessionContext);
	
	const [loading, setLoading] = useState<boolean>(false);
	const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(({target: { files }}) => {
		if (files?.length == 1) {
			setLoading(true);
			const file = files[0];

			uploadDocument(coloApiUrl, token, file, sejour.id, type)
				.then((s: Sejour) => {
					updateSejour(s);
					setLoading(false);
				})
				.catch((err) => {
					console.log('upload error', err);
					setLoading(false);
					addError(err);
				});
		}
	}, [coloApiUrl, token, sejour.id, type, refreshData]);

	const manageValue = useCallback((node: HTMLInputElement | null) => {
		if (node && value) {
			const list = new DataTransfer();

			list.items.add(new File([], (value.type in documentTypes ? documentTypes[value.type].libelle : value.type) + ' - ' + value.id + value.extension));
			node.files = list.files;
		}
	}, [value, documentTypes]);

	const disabled = useMemo(() => disable || value?.status == 'VALIDE', [disable, value?.status]);

	const showDeleteConfirmation = useCallback(() => {
		if (value) {
			setMessage(<SuppressionDocumentConfirm document={value} sejour={sejour} index={index} />)
		}
	}, [value, sejour, index]);
	
	const { url } = useRouteMatch();

	const iconFile = useMemo(() => {
		if (!value) {
			return '';
		}
		const ext = value.extension.toLowerCase();
		if (ext == '.pdf') {
			return 'fa fa-file-pdf-o';
		} else if (['.jpg', '.jpeg', '.gif', '.bmp', '.png'].includes(ext)) {
			return 'fa fa-file-image-o';
		}
		return 'fa fa-file-o';
	}, [value?.extension]);

	return (
		<div className="col-8 d-flex align-items-center">
			{!loading
				? value
					? <>
						<div>{Status[value.status]}</div>
						<Link className="ml-auto mr-2 d-flex" to={url + '/documents/' + value.id}>
							<span className={iconFile} style={{fontSize: 26}}></span>
						</Link>
						<button className={'fa fa-trash-o border-0 bg-transparent m-2 ' + (locked && value.status == 'VALIDE' ? 'text-light' : 'text-danger')} style={{ fontSize: '30px', appearance: 'none' }} onClick={showDeleteConfirmation} disabled={locked && value.status == 'VALIDE'} />
					</>
					: <div>
						<div className="position-relative bg-light">
							{!disabled && <input ref={manageValue} type="file" className="position-absolute h-100 w-100" style={{top: 0, left: 0, cursor: 'pointer'}} onChange={onChangeHandler} />}
							<div className="position-absolute h-100 w-100 bg-white" style={{top: 0, left: 0, pointerEvents: 'none'}}></div>
							<div className="position-relative d-flex align-items-center" style={{pointerEvents: 'none', padding: 9}}>
								<span className="fa fa-cloud-upload text-secondary mr-2" style={{ fontSize: '30px' }}></span>
								<span className="text-muted">Glissez ou cliquez ici pour ajouter un fichier</span>
							</div>
						</div>
					</div>
				: <div className="d-flex align-items-center">
					<div className="spinner-border" style={{height: 24, width: 24, borderWidth: 3, margin: 12}} role="status">
						<span className="sr-only">Loading...</span>
					</div>
					Chargement...
				</div>
			}
		</div>
	)
}

const SuppressionDocumentConfirm = ({document, sejour, index}: {document: Document, sejour: Sejour, index: number}) => {
	const { setMessage, documentTypes, coloApiUrl, token, updateSejour } = useContext(SessionContext);
	
	const [state, setState] = useState<'none' | 'send' | 'error'>('none');
	const deleteDocumentHandler = useCallback(() => {
		setState('send');
		deleteDocument(coloApiUrl, token, document.id)
			.then(() => {
				const indexDoc = sejour.documents!.findIndex(d => d.id == document.id);
				let newList = sejour.documents!.slice();
				newList.splice(indexDoc, 1);
				updateSejour({...sejour, documents: newList});
				setMessage();
			})
			.catch((err) => {
				setState('error');
				console.log(err);
			});
		

	}, [document, sejour, coloApiUrl, token]);
	return (
		<Message
			title="Confirmez la suppression du fichier"
			buttons={<div className="buttons text-center d-flex justify-content-center align-items-center">
				<button disabled={state == 'send'} className="btn btn-secondary mx-1" onClick={() => setMessage()}>Annuler</button>
				<button disabled={state != 'none'} className="btn btn-primary mx-1" onClick={deleteDocumentHandler}>Confirmer</button>
				{state == 'send' && <div className="position-relative" style={{height: '2rem'}}>
					<div className="spinner-border text-primary position-absolute" role="status">
						<span className="sr-only">Chargement...</span>
					</div>
				</div>}
			</div>}
		>
			Voulez vous vraiment supprimer le fichier numéro {index + 1} de type {document.type in documentTypes ? documentTypes[document.type].libelle : document.type} ?
		</Message>
	)
}


const wrapperStyle: React.CSSProperties = { position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', border: '20px solid rgba(0,0,0,0.7)', background: 'white' }
const iframeStyle: React.CSSProperties = { width: '100%', height: '100%', border: 'none', background: 'white', borderRadius: '.25rem' }
const btnFermerStyle: React.CSSProperties = {bottom: 5, left: 5}
const btnCroixStyle: React.CSSProperties = { top: -13,  right: -13, fontSize: 40, background: 'white', borderRadius: '50%', lineHeight: '34px', cursor: 'pointer'}

export const DocumentView = ({id}: {id: string}) => {
	const { coloApiUrl, token } = useContext(SessionContext);

	const { url } = useRouteMatch();
	const urlGenerated = useMemo(() => {
		const pos = url.indexOf('/documents/');
		if (pos > -1) {
			return url.substring(0, pos);
		}
		return '..';
	}, [url]);

	return (
		<div style={wrapperStyle}>
			<iframe src={coloApiUrl + 'documents/' + id + '?displaying=true&token=' + token} style={iframeStyle} />
			<Link to={urlGenerated} className="position-absolute btn btn-secondary" style={btnFermerStyle} role="button">Fermer</Link>
			<Link to={urlGenerated} className="text-decoration-none text-danger fa fa-times-circle position-absolute" style={btnCroixStyle} role="button"></Link>
		</div>
	);
}
