

export const ShowError = (error: any) =>
	typeof(error) == 'string' ? error : JSON.stringify(error);


	
export interface EnfantParam {
	enfantId: string;
}

export interface SejourParam {
	sejourId: string;
}

export const UCFirst = (s: string) => s[0].toLocaleUpperCase() + s.substr(1);
