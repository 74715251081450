import React from "react";


interface ProgressIndicatorProps {
	value: number
	children: React.ReactNode[]
}

export const ProgressIndicator = ({value, children}: ProgressIndicatorProps) =>
	<div className="d-flex position-relative align-items-center justify-content-center m-auto" style={{maxWidth: (children.length) * 250}}>
		<div className="position-absolute bg-gris1" style={{ height: 4, top: 27, left: (50 / children.length) + '%', right: (50 / children.length) + '%'}}>
			<div className="position-absolute bg-primary" style={{top: 0, bottom: 0, left: 0, width: Math.min((Math.ceil(value) / (children.length - 1) * 100), 100) + '%'}}></div>
		</div>
		{children.map((s, i) => 
			<div key={i} className="position-relative" style={{flex: 1}}>
				{Math.min(Math.ceil(value), children.length - 1) == i && <div className="position-absolute bg-primary rounded-circle" style={{width: 58, height: 58, left: 'calc(50% - 29px)'}}></div>}
				<div
					className={'position-absolute d-flex align-items-center justify-content-center rounded-circle border-white text-white ' + (value >= i ? 'bg-primary' : 'bg-gris1')}
					style={{width: 50, height: 50, fontSize: 20, top: 4, left: 'calc(50% - 25px)', borderStyle: 'solid', borderWidth: 3}}>
						{value >=i ? <span className="fa fa-check"></span> : <span className="fa fa-times"></span>}
				</div>
				<div className="text-center" style={{margin: '60px 30px 0 30px'}}>{s}</div>
			</div>	
		)}
	</div>