import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Responsable, updateAddresse, updateReponsables } from '../../api';
import { useResponsables } from '../../utils/ResponsableUtils';
import Wizard from '../../utils/Wizard';
import { SessionContext } from '../SessionContext';
import AdressesWizardPage from '../Wizards/WizardPages/AdressesWizardPage';
import ResponsablesWizardPage from '../Wizards/WizardPages/ResponsablesWizardPage';

export default () => {
	const { debugMode, rootUrl, coloApiUrl, token, addError, refreshData } = useContext(SessionContext);
	const responsables = useResponsables();

	const [responsablesInitialValues, addressInitialValues] = useMemo(() => {
		if (!responsables[1] || responsables[1].adresse.id == responsables[0].adresse.id) {
			return [
				{
					responsable1: {...responsables[0], adresse: undefined},
					responsable2: responsables[1] ? {...responsables[1], adresse: undefined} : undefined
				},
				{
					responsable1: {
						adresse: responsables[0].adresse
					}
				}
			];
		} else {
			return [
				{
					responsable1: {...responsables[0], adresse: undefined},
					responsable2: {...responsables[1], adresse: undefined},
					situation: 'SEPARE'
				},
				{
					responsable1: {
						adresse: responsables[0].adresse
					}
				}
			];
		}
	}, responsables);
	return (
		<div>
			<Link to={rootUrl}>Retour</Link>
			<Wizard
				showDebug={debugMode}
				name="updateResponsables"
				initialValues={responsablesInitialValues}
				cancelComponent={<></>}
				onSubmit={(values: {responsable1: Responsable, responsable2?: Responsable}) => updateReponsables(coloApiUrl, token, values, refreshData, addError)}
			>
				<ResponsablesWizardPage />
			</Wizard>

			
			<Wizard
				showDebug={debugMode}
				name="updateAdresses"
				initialValues={addressInitialValues}
				cancelComponent={<></>}
				onSubmit={(values: {responsable1: Responsable}) => updateAddresse(coloApiUrl, token, values.responsable1.adresse, refreshData, addError)}
			>
				<AdressesWizardPage />
			</Wizard>
			
		</div>
	)
}