import { SejourStatut } from './api';

// interface VoyageTypeProps {
// 	type: VoyageType;
// 	Aller: string;
// 	Retour: string;
// }

// export const VoyageTypeOptions: VoyageTypeProps[] = [
// 	{
// 		type: VoyageType.PERSO,
// 		Aller: 'Je me charge du trajet aller (dont covoiturage)',
// 		Retour: 'Je me charge du trajet retour (dont covoiturage)'
// 	},
// 	{
// 		type: VoyageType.TRAIN_CLERMONT,
// 		Aller: 'Mon enfant viendra en train jusqu\'à Clermont-Ferrand',
// 		Retour: 'Mon enfant rentrera en train depuis Clermont-Ferrand'
// 	},
// 	{
// 		type: VoyageType.CAR_CAMPRIEU,
// 		Aller: 'J\'inscris mon enfant pour le voyage collectif en car (voyage payant : 10.00€ par trajet)',
// 		Retour: 'J\'inscris mon enfant pour le voyage collectif en car (voyage payant : 10.00€ par trajet)'
// 	}
// ];


export const SejourStatutLibelles = {
	[SejourStatut.ACCEPTE]:'accepté',
	[SejourStatut.ANNULE]:'annulé',
	[SejourStatut.REFUSE]:'refusé',
	[SejourStatut.SOUHAITE]:'souhaité',
	[SejourStatut.VALIDE]:'validé'
}