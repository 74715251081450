import React, { useContext } from 'react';
import ChildWizard from '../Wizards/ChildWizard';
import { updateEnfant, Enfant } from '../../api';
import { RouteComponentProps } from 'react-router';
import { SessionContext } from '../SessionContext';
import { EnfantParam } from '../../utils';



export default ({history, match}: RouteComponentProps<EnfantParam>) => {
    const context = useContext(SessionContext);
    const id = parseInt(match.params.enfantId);

    const enfant = context.enfants!.find(e => e.id == id)!;

	const onSubmit = updateEnfant(
        context.coloApiUrl,
		context.token,
		id,
        (enfant: Enfant) => {
            context.setEnfant(enfant);
            history.push(context.rootUrl + '/enfants/' + enfant.id)
        },
        context.addError
    );
    
    return <ChildWizard enfant={enfant} onSubmit={onSubmit} />

};