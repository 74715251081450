import send, { acceptJson, contentTypeJson, headers, tokenAuth } from '../utils/Http';
import { Camp } from "./camps";
import { ApiErrors, processApiErrors, APIResponse } from './commons';
import { Enfant } from './enfants';




export enum SejourStatut {
	/**
	 * Séjour enregistré. En attente d'accpetation du STAFF 
	 */
	SOUHAITE = 'SOUHAITE',
	
	/**
	 * Séjour accepté par le STAFF. En attente des documents, paiements...
	 */
	ACCEPTE = 'ACCEPTE',
	
	/**
	 * Séjour refusé par le STAFF.
	 */
	REFUSE = 'REFUSE',
	
	/**
	 * Séjour validé. Tous les documents, justificatifs sont fournis et valide. Le séjour est payé.
	 */
	VALIDE = 'VALIDE',
	
	/**
	 * Séjour annulé par les responsables de l'enfant
	 */
	ANNULE = 'ANNULE'
}

export enum SensVoyage {
	ALLER = 'Aller',
	RETOUR = 'Retour'
}

// export enum VoyageType {
// 	PERSO = 'PERSO',
// 	CAR_CAMPRIEU = 'CAR_CAMPRIEU',
// 	TRAIN_CLERMONT = 'TRAIN_CLERMONT'
// }
export interface Voyage {
	voyageAller: string,
	voyageAllerInfo: string,
	voyageRetour: string,
	voyageRetourInfo: string
}

// export const LieuxVoyageTypeFiltering = {
// 	[Lieu.CAMPRIEU]: [VoyageType.PERSO, VoyageType.CAR_CAMPRIEU],
// 	[Lieu.SAY]: [VoyageType.PERSO, VoyageType.TRAIN_CLERMONT]
// };


export interface Document {
	id: number
	status: 'EN_ATTENTE' | 'VALIDE' | 'INVALIDE'
	type: string
	extension: string
}

export interface Sejour extends Voyage {
	id: number,
	ordreSouhait: number,
	statut: SejourStatut,
	camp: Camp['id']

	documents?: Document[]
}



export const addSejours = (coloApiUrl: string, token: string, id: number, setEnfant: (enfant: Enfant) => any, setError: (error: any) => any) => 
	(sejoursData: Partial<{cafDepartement?: string, autresAides?: string, sejours: Sejour[]}>): Promise<ApiErrors | undefined> =>
		new Promise((resolve, _reject) => {
			send(
				coloApiUrl + 'enfants/' + id + '/sejours',
				{
					method: 'POST',
					body: JSON.stringify(sejoursData),
					...headers(
						contentTypeJson(),
						acceptJson(),
						tokenAuth(token)
					)
				}
			).then((json: Enfant) => {
				if (json) {
					setEnfant(json)
					resolve(undefined);
				} else {
					setError(json);
					resolve(processApiErrors(json));
				}
			})
			.catch((err) => {
				setError(err);
				resolve(processApiErrors(err));
			});
		});


export const updateVoyage = (coloApiUrl: string, token: string, id: number, voyage: Voyage): Promise<Sejour> => 
	send(
		coloApiUrl + 'sejours/' + id + '/update',
		{
			method: 'POST',
			body: JSON.stringify(voyage),
			...headers(
				contentTypeJson(),
				acceptJson(),
				tokenAuth(token)
			)
		}
	);


export const deleteSejour = (coloApiUrl: string, token: string, id: number): Promise<APIResponse<Enfant, 'enfant'>> =>
	send(
		coloApiUrl + 'sejours/' + id,
		{
			method: 'DELETE',
			...headers(
				acceptJson(),
				tokenAuth(token)
			)
		}
	)