import React from 'react';
import { WizardPage } from '../../../utils/Wizard';
import TextField from '../Components/TextField';
import { Title } from '../Components/WizardComponents';


export default ({values}: {values?: any}) => {
	const situation = values && values.situation || null;
	return (
		<WizardPage>
			<Title>ADRESSE POSTALE</Title>

			<div className="form-row">
				<div className="form-group col-md-6">
					{situation == 'SEPARE' && <Title h="3" className="pb-3">Premier Responsable légal</Title>}
					<AdressePart name="responsable1.adresse" />
				</div>

				{situation == 'SEPARE' && <div className="form-group col-md-6">
					<Title h="3" className="pb-3">Deuxième Responsable légal</Title>
					<AdressePart name="responsable2.adresse" />
				</div>}
			</div>
		</WizardPage>
	);
}

export const AdressePart = ({ name }: {name: string}) => (
	<>
		<div className="form-row">
			<TextField label="Ligne 1" name={name + '.ligne1'} colMd="12" />
		</div>
		<div className="form-row">
			<TextField label="Ligne 2" name={name + '.ligne2'} colMd="12" required={false} />
		</div>
		
		<div className="form-row">
			<TextField label="Code postal" name={name + '.codePostal'} colMd="12" />
		</div>
		
		<div className="form-row">
			<TextField label="Ville" name={name + '.ville'} colMd="12" />
		</div>
		
		<div className="form-row">
			<TextField label="Pays" name={name + '.pays'} colMd="12" />
		</div>
	</>
);
