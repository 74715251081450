import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { SessionContext } from '../../SessionContext';
import { SejourParam, EnfantParam } from '../../../utils';
import BetweenDetail from '../../../utils/BetweenDetail';
import { SejourStatut, Camp, Enfant, deleteSejour, Sejour } from '../../../api';
import { SejourStatutLibelles } from '../../../Libelles';
import { Message } from '../../Message';
import { DocumentsRequired } from './DocumentsRequired';
import { getSejourDocuments } from '../../../ColoApi';
import { DocumentView } from './DocumentItem';
import { AideFinancier } from './AideFianciere';
import { Remarques } from './Remarques';
import InfoVoyage from './InfoVoyage';
import { getDossierProgression } from '../../../utils/DossierUtils';
import { ProgressIndicator } from '../../../utils/ProgressIndicator';



export default ({match}: RouteComponentProps<EnfantParam & SejourParam>) => {

	const childId = parseInt(match.params.enfantId);
	const sejourId = parseInt(match.params.sejourId);

	const context = useContext(SessionContext);
	
	const [enfant, sejourIndex, sejour, camp] = useMemo(() => {
		const enfant = context.enfants!.find(e => e.id == childId)!;
		const sejourIndex = enfant.sejours.findIndex(s => s.id == sejourId);
		
		const sejour = sejourIndex > -1 ? enfant.sejours[sejourIndex] : undefined;
		const camp = sejour ? context.camps[sejour.camp] : undefined;
		return [enfant, sejourIndex, sejour, camp];
	}, [context.enfants, context.camps]);

	if (sejour && camp) {
		return <SejourPage sejourIndex={sejourIndex} sejour={sejour} camp={camp} enfant={enfant} />
	} else {
		return <div>
			Une erreur s'est produite, ce séjour n'existe pas...<br />
			<Link to={context.rootUrl + '/enfants/' + childId}>Retour</Link>
		</div>
	}
}

const SejourPage = ({sejourIndex, sejour, camp, enfant}: {sejourIndex: number, sejour: Sejour, camp: Camp, enfant: Enfant}) => {
	
	const context = useContext(SessionContext);

	const [documentRefs, setDocumentRefs] = useState<{name: string, url: string}[]>();
	useEffect(() => {
		getSejourDocuments(context.resourcesUrl, context.token, sejour.id)
			.then(docs => setDocumentRefs(Object.entries(docs).sort((d1, d2) => d1[0].localeCompare(d2[0])).reduce((prev, [name, url]) => [...prev, {name, url}], [])))
			.catch(() => setDocumentRefs([]));
	}, []);

	const clickOnDeleteSejour = useCallback(() => {
		context.setMessage(<SuppressionSejourConfirm camp={camp} enfant={enfant} sejour={sejour} />);
	}, [camp, enfant, sejour]);
	
	const { path } = useRouteMatch();

	return (
		<div className="container-fluid">
			<div className="container pb-3 pt-5">
				<div className="d-flex">
					<Link to={context.rootUrl + '/enfants/' + enfant.id} className="btn px-3 mr-3" role="button"><span className="fa fa-angle-left"></span></Link>
					
					<h2>{camp.libelle}</h2>
					<h4 className="ml-auto"><BetweenDetail begin={camp.dateDebut} end={camp.dateFin} /></h4>
				</div>

				<div className="my-4">
					<h4>{enfant.prenom} {enfant.nom}</h4>
					<div className="mb-2">
						<div className="mb-2">Séjour {SejourStatutLibelles[sejour.statut]}</div>
						{sejour.statut != SejourStatut.ANNULE
						&& sejour.statut != SejourStatut.REFUSE
						&& <ProgressIndicator value={getDossierProgression(sejour, context.camps, context.documentTypes)}>
							<div>Souhaité</div>
							<div>Accepté</div>
							<div>Validé</div>
						</ProgressIndicator>}
					</div>
					{sejour.statut == SejourStatut.SOUHAITE && <button className="btn btn-primary" onClick={clickOnDeleteSejour}>Supprimer l'inscription</button>}
				</div>

				<div className="my-4">
					<h5>
						Documents
						{!documentRefs && <div className="spinner-border spinner-border-sm ml-2 align-middle" role="status">
							<span className="sr-only">Chargement...</span>
						</div>}
					</h5>
					{documentRefs && 
						(documentRefs.length > 0 
							? <ul>{documentRefs.map(({name, url}, i) => <li key={i}><a href={url} target="_blank">{name}</a></li>)}</ul>
							: <div className="pb-3">Pas de document disponible</div>)
					}
				</div>
				
				<DocumentsRequired sejour={sejour} />
				
				{camp.voyageInfoDef && <InfoVoyage enfant={enfant} sejourId={sejour.id} sejourIndex={sejourIndex} def={camp.voyageInfoDef} />}

				<AideFinancier enfant={enfant} />
				
				<Remarques enfant={enfant} />
			</div>

			<Switch>
				<Route path={path + '/documents/:documentId'} component={({match}: RouteComponentProps<{documentId: string}>) => <DocumentView id={match.params.documentId} />} />
			</Switch>
		</div>
	);
}

const SuppressionSejourConfirm = ({camp, enfant, sejour}: {camp: Camp, enfant: Enfant, sejour: Sejour}) => {
	const { setMessage, rootUrl, coloApiUrl, token, addError, setEnfant } = useContext(SessionContext);
	const { push } = useHistory();
	const validate = useCallback(() => {
		deleteSejour(coloApiUrl, token, sejour.id)
			.then(result => {
				push(rootUrl + '/enfants/' + enfant.id)
				setEnfant(result.enfant);
			})
			.catch(addError)
			.finally(() => setMessage());
	}, [rootUrl, enfant, sejour.id]);

	return (
		<Message
			title="Confirmez l'annulation de l'inscription"
			buttons={<>
				<button className="btn btn-secondary m-1" onClick={() => setMessage()}>Annuler</button>
				<button className="btn btn-primary m-1" onClick={validate}>Valider</button>
			</>}
		>
			Le v{'\u0153'}u de {camp.libelle} <BetweenDetail begin={camp.dateDebut} end={camp.dateFin} ucfirst={false} /> pour {enfant.prenom} sera annulé
		</Message>
	)
}