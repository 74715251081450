import send, { acceptJson, contentTypeJson, headers, tokenAuth } from '../utils/Http';
import { ApiErrors, processApiErrors } from "./commons";
import { Responsable, Sexe } from "./responsables";
import { Sejour } from "./sejours";


export interface Enfant {
	id: number,
	nom: string,
	prenom: string,
	sexe: Sexe,
	dateDeNaissance: string | Date,
	inseeCode: string
	lieuDeNaissance: string,
	remarques: string
	responsable1: Responsable | Responsable['id'],
	responsable2?: Responsable | Responsable['id'],
	sejours: Sejour[],
	cafDepartement?: string
	cafNumeroAllocataire?: string
	autresAides?: string
}


export const enfantList = (coloApiUrl: string, token: string): Promise<Enfant[]> => 
	send(
		coloApiUrl + 'enfants',
		{
			method: 'GET',
			...headers(
				acceptJson(),
				tokenAuth(token)
			)
		}
	);



			
export const addEnfant = (coloApiUrl: string, token: string, setEnfant: (enfant: Enfant) => any, setError: (error: any) => any, updateResponsable?: (user: Responsable) => void) =>
	(values: object): Promise<ApiErrors | undefined> =>
		new Promise((resolve, _reject) => {
			send(
				coloApiUrl + 'pre-inscription', 
				{
					method: 'POST',
					body: JSON.stringify(values), 
					...headers(
						contentTypeJson(),
						acceptJson(),
						tokenAuth(token)
					)
				}
			)
				.then(json => {
					if (json.status && json.status == 200) {
						setEnfant(json.enfant);
						//updateResponsable && updateResponsable(json.enfant.responsable1);
						resolve(undefined);
					} else {
						setError(json);
						resolve(processApiErrors(json));
					}
				})
				.catch((err) => {
					setError(err);
					resolve(processApiErrors(err));
				});
		});

export const updateEnfant = (coloApiUrl: string, token: string, id: number, setEnfant: (enfant: Enfant) => void, setError: (error: any) => any) => 
	(enfant: Partial<Enfant>): Promise<ApiErrors | undefined> =>
		new Promise((resolve, _reject) => {
			send(
				coloApiUrl + 'enfants/' + id,
				{
					method: 'PATCH',
					body: JSON.stringify(enfant),
					...headers(
						contentTypeJson(),
						acceptJson(),
						tokenAuth(token)
					)
				}
			).then((json: any) => {
				if (json) {
					setEnfant(json)
					resolve(undefined);
				} else {
					setError(json);
					resolve(processApiErrors(json));
				}
			})
			.catch((err) => {
				setError(err);
				resolve(processApiErrors(err));
			});
		});


export const deleteEnfant = (coloApiUrl: string, token: string, enfant: Enfant): Promise<any> => 
	send(
		coloApiUrl + 'enfants/' + enfant.id,
		{
			method: 'DELETE',
			...headers(
				acceptJson(),
				tokenAuth(token)
			)
		}
	);