import React from 'react';
import { Responsable, Sexe } from '../api';
import { ValuesWithLabels, ValueWithLabel } from './ValueWithLabel';

export default (r: Responsable) => 
	<ValuesWithLabels>
		<ValueWithLabel name="Genre" value={r.sexe == Sexe.MALE ? 'Monsieur' : 'Madame'} />
		<ValueWithLabel name="Nom" value={r.nom} />
		<ValueWithLabel name="Prénom" value={r.prenom} />
		<ValueWithLabel name="Email" value={r.email} />
		<ValueWithLabel name="Téléphone" value={r.numeroTelephone} />
	</ValuesWithLabels>;

