import send, { acceptJson, headers } from '../utils/Http';




export interface Camp {
	ageMax: number,
	ageMin: number,
	dateDebut: string,
	dateFin: string,
	id: number,
	lieu: string,
	nom: string,
	libelle: string
	nbPlacePrises: number
	placeMax: number
	inscriptionClose: boolean
	documentAttendus: string[]
	voyageInfoDef?: {
		ALLER: {[k: string]: string},
		RETOUR: {[k: string]: string}
	}
}


export const getCampList = (coloApiUrl: string): Promise<Camp[]> => 
	send(coloApiUrl + 'camps', headers(acceptJson()))
		.then((camps: any []) => {
			camps.forEach(camp => {
				if (camp.voyageInfoDef) {
					try {
						const infoDef = JSON.parse(camp.voyageInfoDef);
						if ('ALLER' in infoDef && 'RETOUR' in infoDef) {
							camp.voyageInfoDef = infoDef;
							return;
						}
					} catch (e) {
						console.error(e);
					}
				}
				delete camp.voyageInfoDef
			})
			return camps;
		});