import React, { useState, useContext } from 'react';
import Wizard, { OnSubmitWizardProps } from '../../utils/Wizard';
import ChildWizardPage from './WizardPages/ChildWizardPage';
import ResponsablesWizardPage from './WizardPages/ResponsablesWizardPage';
import SituationWizardPage from './WizardPages/SituationWizardPage';
import AdressesWizardPage from './WizardPages/AdressesWizardPage';
import RemarquesWizardPage from './WizardPages/RemarquesWizardPage';
import SejoursWizardPage from './WizardPages/SejoursWizardPage';
import PageContext, { textColors, labelClasses, bgColors } from './Components/PageContext';
import { SessionContext } from '../SessionContext';
import AideFinanciereWizardPage from './WizardPages/AideFinanciereWizardPage';


export default ({onSubmit}: OnSubmitWizardProps) => {
	const context = useContext(SessionContext);
	const [page, setPage] = useState(0);
	const textColor = textColors[page % textColors.length];
	const labelClass = labelClasses[page % labelClasses.length];

	const submitHandler = (values: any) => 
		onSubmit(values).then((o) => {
			if (o == undefined)
				context.setMessage(<div
					className="position-absolute w-100 h-100 bg-white d-flex flex-column justify-content-center align-items-center"
					style={{zIndex: 1}}
					>
						<h2 className="text-muted m-0 font-weight-normal">Votre enfant {values.prenom} a bien été inscrit</h2>
						<div>Vous allez recevoir un email de confirmation</div>
						<button className="btn btn-primary mt-4" onClick={() => { context.refreshData(); context.setMessage(); }}>Voir le dossier</button>
					</div>
				);
			
			return o;
		});

	return (
		<PageContext.Provider value={{page, textColor, labelClass}}>
			<div className={'container-fluid ' + bgColors[page % bgColors.length] + ' ' + textColor + ' pb-5'}>
				<div className="container home">
					<Wizard
						name="FirstInscriptionWizard"
						onSubmit={submitHandler}
						onChangePage={setPage}
						showDebug={context.debugMode}
						initialValues={{responsable1: {email: context.user.email}}}
						cancelComponent={<button className="btn btn-secondary m-1" type="button" onClick={() => context.resetToken()}>Annuler</button>}
					>
						<SituationWizardPage />
						<ChildWizardPage />
						<ResponsablesWizardPage />
						<AdressesWizardPage />
						<SejoursWizardPage />
						<RemarquesWizardPage />
						<AideFinanciereWizardPage />
					</Wizard>
				</div>
			</div>
		</PageContext.Provider>
	);
};
