import React from 'react';
import { Responsable, Enfant, Camp, DocumentType, Sejour } from '../api';


interface Context {
	resetErrors(): void
	token: string
	resetToken(): void
	// setToken: (token: string) => void;
	
	// Chargement utilisateur & enfants etc
	loading: boolean
	user: Responsable
	updateResponsable(user: Responsable): void
	
	refreshData: () => void

	enfants?: Enfant[] | null
	setEnfant: (enfant: Enfant) => void
	removeEnfant: (enfant: Enfant) => void
	updateSejour: (sejour: Sejour) => void

	camps: { [id: number]: Camp }
	documentTypes: { [type: string]: DocumentType}
	coloApiUrl: string
	resourcesUrl: string
	rootUrl: string
	debugMode: boolean

	setMessage: (c?: React.ReactElement) => any
	message?: React.ReactElement

	addError: (error: any, ...others: any[]) => any
	resetErrors: () => void
	errors?: any[]
}

const noop = () => {};

export const SessionContext = React.createContext<Context>(
	{
		coloApiUrl: '',
		resourcesUrl: '',
		rootUrl: '',
		debugMode: false,
		
		token: '',
		resetToken: noop,
		// setToken: noop,
		user: {} as Responsable,
		updateResponsable: noop,
		refreshData: noop,
		loading: false,
		
		setEnfant: noop,
		removeEnfant: noop,
		updateSejour: noop,
		
		camps: {},
		documentTypes: {},
		
		addError: noop,
		resetErrors: noop,
		setMessage: noop
	}
);