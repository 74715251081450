
import { Camp, Document, DocumentType, Sejour, SejourStatut } from "../api"


export const isDossierComplet = (sejour: Sejour, camps: { [id: number]: Camp }, documentTypes: { [type: string]: DocumentType }): boolean => {
	return isInfoVoyageCompleted(sejour) && isAllDocumentNecessaryUploaded(sejour, camps, documentTypes);
}

export const isInfoVoyageCompleted = (sejour: Sejour): boolean => {
	return !!sejour.voyageAller && !!sejour.voyageRetour;
}

export const isAllDocumentNecessaryUploaded = (sejour: Sejour, camps: { [id: number]: Camp }, documentTypes: {[type: string]: DocumentType }): boolean => {
	
	const sejourDoc: {[type: string]: Document[]} = sejour.documents?.reduce((prev, current) => ({
		...prev,
		[current.type]: [...(prev[current.type] || []), current]
	}), {}) || {};
	
	return !camps[sejour.camp].documentAttendus.some(da => {
		
		if (da in documentTypes && documentTypes[da].obligatoire) {
			if (!(da in sejourDoc)) {
				// document manquant
				return true;
			} else if (sejourDoc[da].some(d => d.status == "INVALIDE")) {
				// document à réuploader
				return true;
			}
			// else if (sejourDoc[da].some(d => d.status == "EN_ATTENTE")) {
				// en attente vérification par un instructeur

			// }
		}
		return false;
		
	});

}



const SejourPoid = {
	[SejourStatut.SOUHAITE]: 0,
	[SejourStatut.ACCEPTE]: 1,
	[SejourStatut.VALIDE]: 2
	//SejourStatut.REFUSE = 'REFUSE',
	//SejourStatut.ANNULE = 'ANNULE'
}
export const getDossierProgression = (sejour: Sejour, camps: { [id: number]: Camp }, documentTypes: {[type: string]: DocumentType }): number => {
	if (!(sejour.statut in SejourPoid)) {
		return 0;
	}

	let rt = SejourPoid[sejour.statut];

	// if (sejour.statut == SejourStatut.ACCEPTE) {
	// 	const sejourDocType: {[type: string]: Document[]} = sejour.documents?.reduce((prev, current) => ({
	// 		...prev,
	// 		[current.type]: [...(prev[current.type] || []), current]
	// 	}), {}) || {};

	// 	const {tot, missing} = camps[sejour.camp].documentAttendus.reduce((prev, da) => {
	// 		let tot = prev.tot;
	// 		let missing = prev.missing;
	// 		if (da in documentTypes && documentTypes[da].obligatoire) {
	// 			tot++;
	// 			if (!(da in sejourDocType)) {
	// 				// document manquant
	// 				// console.log('missing doc', da, sejourDocType);
	// 				missing++;
	// 			} else if (sejourDocType[da].some(d => d.status == "INVALIDE")) {
	// 				// document à réuploader
	// 				// console.log('has invalid doc', da, sejourDocType);
	// 				missing++;
	// 			}
	// 			// else if (sejourDoc[da].some(d => d.status == "EN_ATTENTE")) {
	// 				// en attente vérification par un instructeur

	// 			// }
	// 		}
	// 		return {tot, missing};
	// 	}, {tot: 2, missing: (!sejour.voyageAller ? 1 : 0) + (!sejour.voyageRetour ? 1 : 0)});
		
	// 	// console.log('calcul [base ' + rt + ' + missing ' + missing + '/tot ' + tot +']');
	// 	if (tot > 0) {
	// 		// console.log('>>>', rt + ((tot - missing) / tot));
	// 		return rt + ((tot - missing) / tot);
	// 	}
	// }
	return rt;
}
