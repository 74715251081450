import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Responsable, Enfant, deleteEnfant, Camp, getCampList, enfantList, getDocumentTypes, DocumentType, Sejour, getProfile} from '../api';
import { SessionContext } from './SessionContext';
import { useHistory, useLocation } from 'react-router-dom';
import InscriptionMain from './InscriptionMain';
import { LoginPage } from './Pages/LoginPage';


interface SessionProps {
	coloApiUrl: string;
	resourcesUrl: string;
	rootUrl: string;
	onError?: (error: Object) => any;
	debugMode: boolean;
}

const SessionProvider = ({ coloApiUrl, resourcesUrl, rootUrl, children, onError, debugMode, token, resetToken, user, updateResponsable, refreshProfile }:
	SessionProps & {children: React.ReactNode, token: string, resetToken(): void, user: Responsable, updateResponsable(user: Responsable): void, refreshProfile(): void}) => {
		
	const [errors, setErrors] = useState<any[]>([]);
	const addError = useCallback((e: Object, ...others: any) => {
		console.log(e);
		setErrors([...errors, e].concat(others));
		onError && onError(e);
		return e;
	}, [onError]);
	const resetErrors = useCallback(() => setErrors([]), []);
	
	const [enfants, setEnfants] = useState<Enfant[] | null>();
	const [enfantLoading, setEnfantLoading] = useState<boolean>(true);
	const [documentTypes, setDocumentTypes] = useState<{[type: string]: DocumentType}>({});
	useEffect(() => {
		setEnfantLoading(true);
		enfantList(coloApiUrl, token)
			.then(setEnfants)
			.catch(err => addError(err) && setEnfants(null))
			.finally(() => setEnfantLoading(false));
		
		getDocumentTypes(coloApiUrl, token)
			.then(dtList => dtList.reduce((prev, dt) => ({...prev, [dt.code]: dt}), {}))
			.then(setDocumentTypes);
	}, [user, token]);

	const refreshData = useCallback(() => {
		refreshProfile();
		// enfantList(coloApiUrl, token)
		// 	.then(setEnfants)
		// 	.catch(err => addError(err) && setEnfants(null))
	}, [refreshProfile/*, coloApiUrl, token*/])

	
	const [camps, setCamps] = useState<{[id: number]: Camp }>({});
	const [campLoading, setCampLoading] = useState<boolean>(true);
	useEffect(() => {
		setCampLoading(true);
		getCampList(coloApiUrl)
			.then(camps => {
				let campsRef: {[id: number]: Camp} = {};
				for (let i = 0; i < camps.length; i++)
					campsRef[camps[i].id] = camps[i];
				
				setCamps(campsRef);
			})
			.catch(addError)
			.finally(() => setCampLoading(false));
	}, [coloApiUrl]);
	
	const setEnfant = useCallback((enfant: Enfant) => {
		let tmpEnfants = enfants;
		if (!tmpEnfants) tmpEnfants = [];

		const i = tmpEnfants.findIndex(e => e.id == enfant.id);
		if (i > -1) {
			tmpEnfants[i] = enfant;
		} else {
			tmpEnfants.push(enfant);
		}
		setEnfants(Object.assign([], tmpEnfants));
	}, [enfants]);
	const removeEnfant = useCallback((enfant: Enfant) => {
		if (!enfants) return;

		const i = enfants.findIndex(e => e.id == enfant.id);
		if (i > -1) {
			enfants.splice(i, 1);
			setEnfants(Object.assign([], enfants));
			deleteEnfant(coloApiUrl, token, enfant)
				.catch(addError);
		}
	}, [enfants, coloApiUrl, token]);

	const updateSejour = useCallback((sejour: Sejour) => {
		// console.log('update sejour', sejour);
		setEnfants(prev => {

			if (!prev) {
				return prev;
			}

			return prev.map(e => {
				const indexSejour = e.sejours.findIndex(s => s.id == sejour.id);
				if (indexSejour > -1) {
					let newSejours = e.sejours.slice();
					newSejours.splice(indexSejour, 1, sejour);
					return {
						...e,
						sejours: newSejours
					};
				}
				return e;
			});

		});
	}, []);

	const [message, setLocalMessage] = useState<React.ReactElement>();
	const setMessage = useCallback((c?: React.ReactElement) => {
		setLocalMessage(c);
		resetErrors();
	}, []);

	const value = useMemo(() => ({token, resetToken, user, updateResponsable, refreshData, loading: enfantLoading || campLoading, enfants, setEnfant, removeEnfant, camps, coloApiUrl, resourcesUrl, rootUrl, debugMode, addError, resetErrors, errors, message, setMessage, documentTypes, updateSejour}),
		[token, resetToken, user, updateResponsable, refreshData, enfantLoading, campLoading, enfants, setEnfant, removeEnfant, camps, coloApiUrl, resourcesUrl, rootUrl, debugMode, addError, resetErrors, errors, message, setMessage, documentTypes]);

	return (
		<SessionContext.Provider value={value}>
			{children}
		</SessionContext.Provider>
	);
};



const SessionEntryPoint = ({coloApiUrl, rootUrl, resourcesUrl, debugMode}: {coloApiUrl: string, rootUrl: string, resourcesUrl: string, debugMode: boolean}) => {
	const [token, setToken] = useState<string | undefined>(window.localStorage.token);
	const resetToken = useCallback(() => setToken(undefined), []);

	useEffect(() => {
		// save token
		if (window.localStorage.token != token) {
			if (token) {
				window.localStorage.token = token;
			} else {
				delete window.localStorage.token;
			}
		}
	}, [token]);

	const location = useLocation();
	const { push } = useHistory();
	
	useEffect(() => {
		let searchParams = new URLSearchParams(location.search);
		if (searchParams.has('set-token')) {
			setToken(searchParams.get('set-token') || undefined);
			searchParams.delete('set-token');
			const searchPart = searchParams.toString();
			const newPath = location.pathname + (searchPart.length ? '?' + searchPart : '') + location.hash;
			push(newPath);
		}
	}, [location.search]);

	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<Responsable>();

	const refreshProfile = useCallback(() => {
		if (token) {
			setLoading(true)
			getProfile(coloApiUrl, token)
				.then(setUser)
				.catch(err => {
					console.log(err)
					if (err && 'status' in err && err.status == 401) {
						setUser(undefined);
						resetToken();
					}
				})
				.finally(() => setLoading(false));
		} else {
			setLoading(false)
		}
	}, [token, coloApiUrl]);

	useEffect(refreshProfile, [refreshProfile]);

	if (loading) {
		return (
			<div>
				Chargement
			</div>
		)
	} else if (token && user) {
		return (
			<SessionProvider coloApiUrl={coloApiUrl} rootUrl={rootUrl} debugMode={debugMode} resourcesUrl={resourcesUrl} user={user} updateResponsable={setUser} refreshProfile={refreshProfile} token={token} resetToken={resetToken}>
				<InscriptionMain />
			</SessionProvider>
		);
	}

	return <LoginPage coloApiUrl={coloApiUrl} rootUrl={rootUrl} />;

}


export default SessionEntryPoint;