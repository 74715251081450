import React, { useEffect } from 'react';
import { WizardPage } from '../../../utils/Wizard';
import { Title } from '../Components/WizardComponents';
import RadioBtns from '../Components/RadioBtns';
import { useForm } from 'react-final-form';


export default ({responsables}: {responsables: any}) => {

	responsables['un-seul-responsable'] = 'Pas de deuxième responsable';
	responsables['autre'] = 'Autre situation';
	
	const format = (value: any) => {
		let rt = typeof(value) == 'object' ? 'autre' : typeof(value) == 'undefined' ? 'un-seul-responsable' : value + '';
		console.log('format', value, rt);
		return rt;
	};

	const parse = (value: any) => {
		let rt = value == 'autre' ? {} : value == 'un-seul-responsable' ? undefined : isNaN(value) ? value : parseInt(value);
		console.log('parse', value, rt);
		return rt;
	};

	const form = useForm();
	useEffect(() => {
		form.change('situation', undefined);
	});

	return (
		<WizardPage>
			<Title>Responsables légaux</Title>


			<div className="form-row">
				<RadioBtns label="Deuxième responsable légal" name="responsable2" items={responsables} colMd="12" format={format} parse={parse} />
			</div>

		</WizardPage>
	);
}