import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormApi } from 'final-form';

export interface FieldsErrors {
	[k: string]: any
}

export interface OnSubmitWizardProps {
	onSubmit: (values: Object) => Promise<FieldsErrors | undefined>;
}
interface WizardProps extends OnSubmitWizardProps {
	initialValues?: any;
	onChangePage?: (page: number) => void;
	cancelComponent?: React.ReactNode;
	children: React.ReactElement[] | React.ReactElement;
	showDebug?: boolean;
	name: string
}



export const WizardPage = ({ children }: any) => children;

export default (props: WizardProps) => {
	const formNode = useRef<HTMLFormElement>(null);

	const [wizardKey, initialValues] = useMemo(() => {
		const key = btoa(props.name + ':' +JSON.stringify(props.initialValues));
		if (window.sessionStorage.getItem('wizardKey') == key)
			return [key, window.sessionStorage.getItem('wizardValues') && JSON.parse(window.sessionStorage.getItem('wizardValues')!) || props.initialValues || {}]
		
		return [key, props.initialValues || {}];
	}, [props.name, props.initialValues]);
	// const [initialValues] = useState(() => window.sessionStorage.getItem('wizardValues') && JSON.parse(window.sessionStorage.getItem('wizardValues')!) || props.initialValues || {});
	const [page, setPage] = useState(0);
	const [formValues, setFormValues] = useState(initialValues);
	const [fieldsPage, setFieldsPage] = useState<{[key: number]: string[]}>({});

	const next = useCallback((values: Object) => {
		const childs = React.Children.toArray<React.ReactElement>(props.children);
		const nbChilds = childs.length - 1;
		let tpage = Math.min(page + 1, nbChilds);

		let activePage = childs[tpage];
		while (
			tpage <= nbChilds &&
			activePage.props.condition &&
			!activePage.props.condition(values)
		) {
			activePage = childs[++tpage];
		}

		setFormValues(values);
		setPage(tpage);
		props.onChangePage && props.onChangePage(tpage);
		formNode.current && formNode.current.scrollIntoView();
		window.sessionStorage.setItem('wizardKey', wizardKey);
		window.sessionStorage.setItem('wizardValues', JSON.stringify(values));
	}, [props.children, page, props.onChangePage]);

	const previous = useCallback(() => {
		let tpage = Math.max(page - 1, 0);

		let activePage = React.Children.toArray<React.ReactElement>(props.children)[tpage];
		while (
			tpage >= 0 &&
			activePage.props.condition &&
			!activePage.props.condition(formValues)
		) {
			activePage = React.Children.toArray<React.ReactElement>(props.children)[--tpage];
		}

		setPage(tpage);
		props.onChangePage && props.onChangePage(tpage);
		formNode.current && formNode.current.scrollIntoView();
	}, [props.children, page, props.onChangePage]);

	const forcePage = useCallback((num: number) => {
		setPage(num);
		props.onChangePage && props.onChangePage(num);
		formNode.current && formNode.current.scrollIntoView();
	}, [props.onChangePage])

	const validate = useCallback((values: Object) => {
		const activePage = React.Children.toArray<React.ReactElement>(props.children)[page];
		return activePage.props.validate ? activePage.props.validate(values) : {};
	}, [props.children, page]);

	const processErrors = useCallback((errors?: FieldsErrors) => {
		if (errors) {
			const keys = Object.keys(errors);
			if (keys.length > 0)
			{
				const fieldName = keys[0];
				for (let i in fieldsPage) {
					if (~fieldsPage[i].indexOf(fieldName)) {
						forcePage(parseInt(i));
						return;
					}
				}
			}
		}
	}, [fieldsPage, forcePage]);

	const handleSubmit = useCallback((values: Object, form: FormApi) => 
		new Promise((resolve, reject) => {
			const { children, onSubmit } = props;
			const isLastPage = page === React.Children.count(children) - 1;
			const activePage = React.Children.toArray<React.ReactElement>(children)[page];

			setFieldsPage({...fieldsPage, [page]: form.getRegisteredFields()});

			if (isLastPage) {
				onSubmit(values)
					.then((errors?: FieldsErrors) => {
						processErrors(errors);
						if (!errors) {
							window.sessionStorage.removeItem('wizardValues');
							window.sessionStorage.removeItem('wizardKey');
							//props.onSuccess && props.onSuccess(values);
						}
						resolve(errors);
					})
					.catch(reject);
			} else if (!activePage.props.validatePage || activePage.props.validatePage(values)) {
				next(values);
				resolve(undefined);
			} else {
				reject();
			}
		}), [props.children, props.onSubmit, page, fieldsPage, processErrors, next]);
	

	const { children } = props;
	//const { page, values } = state;
	const activePage = React.Children.toArray<React.ReactElement>(children)[page];
	const isLastPage = page === React.Children.count(children) - 1;
	return (
		<Form
			mutators={{ ...arrayMutators }}
			initialValues={initialValues}
			validate={validate}
			onSubmit={handleSubmit}>
			{form => (
				<form onSubmit={form.handleSubmit} autoComplete="off" ref={formNode}>
					{React.cloneElement(activePage, form)}
					<div className="buttons text-center d-flex justify-content-center align-items-center">
						{(page == 0 && props.cancelComponent) || (
							<button
								className="btn btn-secondary m-1"
								type="button"
								onClick={previous}
								disabled={page == 0}>
								Précédent
							</button>
						)}
						<button
							className="btn btn-primary m-1"
							type="submit"
							disabled={form.submitting || form.validating}>
							{isLastPage ? 'Valider' : 'Suivant'}
						</button>
						{form.validating && (<div className="position-relative" style={{height: '2rem'}}>
								<div className="spinner-border text-primary position-absolute" role="status">
									<span className="sr-only">Chargement...</span>
								</div>
							</div>
						)}
					</div>

					{props.showDebug &&
					<>
						<pre>{JSON.stringify(form.values, null, 2)}</pre>
						<pre style={{ color: 'red' }}>{JSON.stringify(form.errors, null, 2)}</pre>
						<pre style={{ color: 'red' }}>{JSON.stringify(form.submitErrors, null, 2)}</pre>
					</>
					}
				</form>
			)}
		</Form>
	);
	
}
