import React, { useEffect } from 'react';
import { WizardPage } from '../../../utils/Wizard';
import RadioBtns from '../Components/RadioBtns';
import { useField, useForm } from 'react-final-form';
import EmailField from '../Components/EmailField';
import { Title } from '../Components/WizardComponents';


export default ({}: any) => {
	const {input: {value}} = useField('situation', {subscription: {value: true}});
	const form = useForm();
	useEffect(() => {
		if (value == 'SEUL')
			form.change('responsable2', undefined);
	}, [value]);

	return (
		<WizardPage>
			<Title>VOTRE SITUATION</Title>
			<div className="form-row">
				<RadioBtns label="Situation familiale" name="situation" items={{MARIE:'Je suis marié', SEUL:'J\'élève seul mon enfant', SEPARE:'Les deux Resposables légaux vivent à des adresses différentes'}} colMd="12" />
			</div>
			
			{value == 'MARIE' && <EmailField label="Email deuxième responsable" name="responsable2.email" helperText="Entrez l'email du deuxième responsable pour continuer (Non obligatoire)" required={false} />}
			{value == 'SEPARE' && <EmailField label="Email deuxième responsable" name="responsable2.email" helperText="Entrez l'email du deuxième responsable pour continuer" />}
		</WizardPage>
	);
};
;