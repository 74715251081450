import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

export default ({begin, end, format, ucfirst = true}:{begin: Date | string, end: Date | string, format?: any, ucfirst?: boolean}) => {
	const b = moment(begin);
	const e = moment(end);
	return (
		<>
			{ucfirst ? 'D' : 'd'}u {b.format(b.year() != e.year() ? format && format.year || 'D MMMM YYYY' : b.month() != e.month() ? format && format.month || 'D MMMM' : format && format.day || 'D') + ' '}
			au {e.format(format && format.year || 'D MMMM YYYY')}
		</>
	);
};