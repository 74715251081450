import React, { useContext, useEffect, useState } from "react";
import { Form, FormRenderProps, useForm } from "react-final-form";
import { Enfant, updateEnfant } from "../../../api";
import { SessionContext } from "../../SessionContext";
import Checkbox from "../../Wizards/Components/Checkbox";
import TextField from "../../Wizards/Components/TextField";


export const AideFinancier = ({enfant}: {enfant: Enfant}) => {
	const context = useContext(SessionContext);

	const onSubmit = updateEnfant(
        context.coloApiUrl,
		context.token,
		enfant.id,
        (rt: Enfant) => {
            context.setEnfant({...rt, sejours: enfant.sejours});
        },
        context.addError
    );

	return (
		<div className="mt-4 mb-7">
			<h5>Bénéficiez-vous d'aides financières ?</h5>
			<Form onSubmit={onSubmit} initialValues={{cafDepartement: enfant.cafDepartement, cafNumeroAllocataire: enfant.cafNumeroAllocataire, autresAides: enfant.autresAides}}>
				{form => <FormAideFinancieres form={form} />}
			</Form>
		</div>
	)
}

const FormAideFinancieres = ({form}: {form: FormRenderProps<{cafDepartement?: string | null, cafNumeroAllocataire?: string | null, autresAides?: string | null}>}) => {
	const [hasAideCaf, setAideCaf] = useState(!!form.initialValues.cafDepartement);
	const [hasAidesAutres, setAidesAutres] = useState(!!form.initialValues.autresAides);
	const formApi = useForm();
	useEffect(() => {
		if (!hasAideCaf && form.values.cafDepartement) {
			formApi.change('cafDepartement', '');
		}
		if (!hasAideCaf && form.values.cafNumeroAllocataire) {
			formApi.change('cafNumeroAllocataire', '');
		}
	}, [hasAideCaf]);
	
	useEffect(() => {
		if (!hasAidesAutres && form.values.autresAides) {
			formApi.change('autresAides', '');
		}
	}, [hasAidesAutres]);
	
	return (
		<form onSubmit={form.handleSubmit} autoComplete="off">
			<div className="container">
				<Checkbox label="De la CAF" checked={hasAideCaf} onChange={setAideCaf} />
				<div className={(!hasAideCaf ? 'd-none ' : '') + 'form-row'}>
					<TextField name="cafDepartement" label="Département de votre CAF" required={hasAideCaf} />
					<TextField name="cafNumeroAllocataire" label="Numéro allocataire" required={hasAideCaf} />
				</div>
			</div>

			<div className="container">
				<Checkbox label="Autres aides" checked={hasAidesAutres} onChange={setAidesAutres} /> 
				<div className={!hasAidesAutres ? 'd-none' : ''}>
					<TextField name="autresAides" label="Listez les aides dont vous pouvez bénéficier" required={hasAidesAutres} />
				</div>
			</div>
			<div className="d-flex align-items-center">
				{!form.pristine && <button className="btn btn-primary" type="submit" disabled={form.invalid || form.submitting || form.validating}>Sauvegarder</button>}
				{/* <span className="pl-2 text-danger">{error}</span> */}
				{form.submitting && <div className="position-relative pl-2" style={{height: '2rem'}}>
					<div className="spinner-border text-primary position-absolute" role="status">
						<span className="sr-only">Chargement...</span>
					</div>
				</div>}
			</div>
		</form>
	)
}