import React, { useContext, useMemo } from 'react';
import { WizardPage } from '../../../utils/Wizard';
import { Title } from '../Components/WizardComponents';
import { useFieldArray } from 'react-final-form-arrays';
import BetweenDetail from '../../../utils/BetweenDetail';
import { SessionContext } from '../../SessionContext';
import { Camp } from '../../../api';

const validateSejours = (values: any) => values && values.length ? null : 'Sélectionnez au moins un camp';

export default ({filterAlreadySelectedForChildId}: {filterAlreadySelectedForChildId?: number}) => {
	const context = useContext(SessionContext);
	const camps: Camp[] = useMemo(() => {
		if (filterAlreadySelectedForChildId) {
			const enfant = context.enfants?.find(e => e.id == filterAlreadySelectedForChildId);
			if (enfant) {
				const campsToFiltered = enfant.sejours.map(s => s.camp);
				return Object.values(context.camps).filter(c => !campsToFiltered.includes(c.id));
			}
		}

		return Object.values(context.camps);
	}, [context.enfants, context.camps, filterAlreadySelectedForChildId]);
	
	return (
		<WizardPage>
			<Title>SOUHAITS</Title>

			{camps.length > 0 ? <CampsSelector name="sejours" camps={camps} validate={validateSejours} /> : <div>Chargement...</div>}
			
		</WizardPage>
	);
};

interface Sejour { camp: number, ordreSouhait: number}

const CampsSelector = ({name, camps, validate}: {name: string, camps: Camp[], validate?: (value?: Sejour) => any}) => {

	const {fields, meta} = useFieldArray(name, {validate});
	//console.log(fields, meta);

	const values = fields.value || [];
	values.forEach((v: Sejour | undefined, i: number) => v && v.ordreSouhait != i && fields.update(i, Object.assign({}, v, {ordreSouhait: i})));

	const currentDate = new Date();
	const filteredCamps = camps.filter(c => new Date(c.dateDebut.substring(0, 10)) > currentDate).filter(c => values.findIndex((v: any) => v && v.camp == c.id) == -1);

	return <div>
		{meta.error && <div className="alert bg-green text-white">{meta.error}</div>}

		A sélectionner :
		<table className={'table' + (filteredCamps.length > 0 ? ' table-hover' : '')}>
			<thead>
				<tr>
					<th>Nom</th>
					<th>Dates</th>
					<th>Ages</th>
					<th>Nb inscrits</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{filteredCamps.map(c => <CampView key={c.id} camp={c} action="select" onClick={(value: any) => { value.ordreSouhait = values.length; fields.push(value);}} />)}
				{filteredCamps.length == 0 && <tr><td colSpan={5} className="text-muted">{values.length == 0 ? 'Aucun camp à sélectionner' : 'Tous les camps sont sélectionnés'}</td></tr>}
			</tbody>
		</table>

		Sélectionné{values.length > 1 ? 's' : ''} :
		<table className={'table' + (values.length > 0 ? ' table-hover' : '')}>
			<thead>
				<tr>
					<th>Voeu</th>
					<th>Nom</th>
					<th>Dates</th>
					<th>Ages</th>
					<th>Nb incrits</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{values.map((v: any, i: number) => { const c = camps.find((c: any) => c.id == v.camp)!; return <CampView key={c.id} num={v.ordreSouhait + 1} camp={c} action="Supprimer" onClick={() => fields.remove(i)} />; })}
				{values.length == 0 && <tr><td colSpan={6} className="text-muted">Pas de camp sélectionné</td></tr>}
			</tbody>
		</table>
	</div>;
};

const CampView = ({camp, action, onClick, num}: {camp: Camp, action: string, onClick: (p: {camp: number}) => any, num?: number}) => {
	const campComplet = camp.inscriptionClose;// camp.placeMax <= camp.nbPlacePrises;

	return (
		<tr onClick={campComplet ? undefined : () => onClick({camp: camp.id})} style={campComplet ? {backgroundColor: 'transparent', opacity: 0.7, cursor: 'default'} : {cursor: 'pointer'}}>
			{num && <td>{num}</td>}
			<td>
				{camp.libelle}
			</td>
			<td>
				<BetweenDetail begin={camp.dateDebut} end={camp.dateFin} />{' '}
			</td>
			<td>
				De {camp.ageMin} à {camp.ageMax} ans
			</td>
			<td className="align-middle">
				<div className="progress" style={{width: '100px'}}>
					<div className={'progress-bar' + (campComplet ? ' bg-danger' : '')} style={{width: (campComplet ? '100' : Math.min(camp.nbPlacePrises, camp.placeMax) / camp.placeMax * 100) + '%'}}></div>
				</div>
			</td>
			<td className="text-right">
				{!campComplet && (action == 'select' ? <span className="fa fa-plus"></span> : <span className="fa fa-times"></span>)}
			</td>
		</tr>
	);
}