import React, { useState } from 'react';
import { WizardPage } from '../../../utils/Wizard';
import { Title } from '../Components/WizardComponents';
import TextField from '../Components/TextField';
import Checkbox from '../Components/Checkbox';


export default () => {
	const [hasAideCaf, setAideCaf] = useState(false);
	const [hasAidesAutres, setAidesAutres] = useState(false);

	return (
		<WizardPage>
			<Title>Bénéficiez-vous d'aides financières ?</Title>

			<div className="container">
				<Checkbox label="De la CAF" onChange={setAideCaf} />
				
				{hasAideCaf && <div className="form-row">
					<TextField name="cafDepartement" label="Département de votre CAF" required={hasAideCaf} />
					<TextField name="cafNumeroAllocataire" label="Numéro allocataire" required={hasAideCaf} />
				</div>}

			</div>

			<div className="container">
				<Checkbox label="Autres aides" onChange={setAidesAutres} /> 
				
				{hasAidesAutres && <TextField name="autresAides" label="Listez les aides dont vous pouvez bénéficier" required={hasAidesAutres} />}
			</div>

		</WizardPage>
	);
};