import send, { headers, acceptJson } from './utils/Http';



// -- Types
// export interface Adresse {
// 	id: number,
// 	ligne1: string
// 	ligne2?: string,
// 	ville: string,
// 	codePostal: string,
// 	pays: string
// }

// export enum Sexe {
// 	MALE = 'MALE',
// 	FEMALE = 'FEMALE'
// }

// export interface Responsable {
// 	id: number,
// 	nom: string,
// 	prenom: string,
// 	sexe: Sexe,
// 	email: string,
// 	numeroTelephone: string
// 	adresse: Adresse
// }

// export interface Camp {
// 	ageMax: number,
// 	ageMin: number,
// 	dateDebut: string,
// 	dateFin: string,
// 	id: number,
// 	lieu: Lieu,
// 	nom: string,
// 	libelle: string
// 	nbPlacePrises: number
// 	placeMax: number
// 	inscriptionClose: boolean
// 	documentAttendus: string[]
// }

// export interface DocumentType {
// 	code: string
// 	libelle: string
// 	multipage: boolean
// }

// export enum SejourStatut {
// 	/**
// 	 * Séjour enregistré. En attente d'accpetation du STAFF 
// 	 */
// 	SOUHAITE = 'SOUHAITE',
	
// 	/**
// 	 * Séjour accepté par le STAFF. En attente des documents, paiements...
// 	 */
// 	ACCEPTE = 'ACCEPTE',
	
// 	/**
// 	 * Séjour refusé par le STAFF.
// 	 */
// 	REFUSE = 'REFUSE',
	
// 	/**
// 	 * Séjour validé. Tous les documents, justificatifs sont fournis et valide. Le séjour est payé.
// 	 */
// 	VALIDE = 'VALIDE',
	
// 	/**
// 	 * Séjour annulé par les responsables de l'enfant
// 	 */
// 	ANNULE = 'ANNULE'
// }

// export enum SensVoyage {
// 	ALLER = 'Aller',
// 	RETOUR = 'Retour'
// }

// export enum VoyageType {
// 	PERSO = 'PERSO',
// 	CAR_CAMPRIEU = 'CAR_CAMPRIEU',
// 	TRAIN_CLERMONT = 'TRAIN_CLERMONT'
// }
// export interface Voyage {
// 	voyageAller: string,
// 	voyageAllerInfo: string,
// 	voyageRetour: string,
// 	voyageRetourInfo: string
// }

// export enum Lieu {
// 	CAMPRIEU = 'CAMPRIEU',
// 	SAY = 'SAY'
// }

// export const LieuxVoyageTypeFiltering = {
// 	[Lieu.CAMPRIEU]: [VoyageType.PERSO, VoyageType.CAR_CAMPRIEU],
// 	[Lieu.SAY]: [VoyageType.PERSO, VoyageType.TRAIN_CLERMONT]
// };


// export interface Document {
// 	id: number
// 	page: number
// 	status: 'EN_ATTENTE' | 'VALIDE' | 'INVALIDE'
// 	type: string
// 	extension: string
// }

// export interface Sejour extends Voyage {
// 	id: number,
// 	ordreSouhait: number,
// 	statut: SejourStatut,
// 	camp: Camp['id']

// 	documents?: Document[]
// }

// export interface Enfant {
// 	id: number,
// 	nom: string,
// 	prenom: string,
// 	sexe: Sexe,
// 	dateDeNaissance: string | Date,
// 	inseeCode: string
// 	lieuDeNaissance: string,
// 	remarques: string
// 	responsable1: Responsable | Responsable['id'],
// 	responsable2?: Responsable | Responsable['id'],
// 	sejours: Sejour[],
// 	cafDepartement?: string
// 	autresAides?: string
// }


// -- Errors
// interface FormError {
// 	errors: FieldError[];
// }

// interface FieldError {
// 	field: string
// 	code: string;
// 	defaultMessage: string;
// }


// export interface ApiErrors {
// 	[k: string]: any
// }

// const processApiErrors = (err?: {json: FormError} | Object): ApiErrors | undefined => {
// 	let errors = undefined;
// 	if (err) {
// 		if (err && 'json' in err && 'errors' in err.json && err.json.errors.length > 0) {
// 			errors = {}
// 			for (let i = 0; i < err.json.errors.length; i++) {
// 				errors[err.json.errors[i].field] = err.json.errors[i].defaultMessage;
// 			}
// 		} else {
// 			errors = {[FORM_ERROR]: err};
// 		}
// 	}
// 	return errors;
// }
	
// -- API

// export const getCampList = (coloApiUrl: string): Promise<Camp[]> => 
// 	send(coloApiUrl + 'camps', headers(acceptJson()));

// export const getDocumentTypes = (coloApiUrl: string, token: string): Promise<DocumentType[]> =>
// 	send(coloApiUrl + 'documents/types', headers(acceptJson(), tokenAuth(token)));

// type APIResponse<T, K extends string> = {
// 	[k in K]: T
// } & {
// 	status: 200 | 401 | 403 | 500
// 	messsage: string
// }

// const preInscriptionApiCall = (coloApiUrl: string, values: object, token: string): Promise<APIResponse<Enfant, 'enfant'>> => 
// 	send(
// 		coloApiUrl + 'pre-inscription', 
// 		{
// 			method: 'POST',
// 			body: JSON.stringify(values), 
// 			...headers(
// 				contentTypeJson(),
// 				acceptJson(),
// 				tokenAuth(token)
// 			)
// 		}
// 	);



			
// export const addEnfant = (coloApiUrl: string, token: string, setEnfant: (enfant: Enfant) => any, setError: (error: any) => any, updateResponsable?: (user: Responsable) => void) =>
// 	(values: object): Promise<ApiErrors | undefined> =>
// 		new Promise((resolve, _reject) => {
// 			preInscriptionApiCall(coloApiUrl, values, token)
// 				.then(json => {
// 					if (json.status && json.status == 200) {
// 						setEnfant(json.enfant);
// 						//updateResponsable && updateResponsable(json.enfant.responsable1);
// 						resolve(undefined);
// 					} else {
// 						setError(json);
// 						resolve(processApiErrors(json));
// 					}
// 				})
// 				.catch((err) => {
// 					setError(err);
// 					resolve(processApiErrors(err));
// 				});
// 		});

// export const updateEnfant = (coloApiUrl: string, token: string, id: number, setEnfant: (enfant: Enfant) => any, setError: (error: any) => any) => 
// 	(enfant: Partial<Enfant>): Promise<ApiErrors | undefined> =>
// 		new Promise((resolve, _reject) => {
// 			send(
// 				coloApiUrl + 'enfants/' + id,
// 				{
// 					method: 'PATCH',
// 					body: JSON.stringify(enfant),
// 					...headers(
// 						contentTypeJson(),
// 						acceptJson(),
// 						tokenAuth(token)
// 					)
// 				}
// 			).then((json: any) => {
// 				if (json) {
// 					setEnfant(json)
// 					resolve(undefined);
// 				} else {
// 					setError(json);
// 					resolve(processApiErrors(json));
// 				}
// 			})
// 			.catch((err) => {
// 				setError(err);
// 				resolve(processApiErrors(err));
// 			});
// 		});


// export const addSejours = (coloApiUrl: string, token: string, id: number, setEnfant: (enfant: Enfant) => any, setError: (error: any) => any) => 
// 	(sejoursData: Partial<{cafDepartement?: string, autresAides?: string, sejours: Sejour[]}>): Promise<ApiErrors | undefined> =>
// 		new Promise((resolve, _reject) => {
// 			send(
// 				coloApiUrl + 'enfants/' + id + '/sejours',
// 				{
// 					method: 'POST',
// 					body: JSON.stringify(sejoursData),
// 					...headers(
// 						contentTypeJson(),
// 						acceptJson(),
// 						tokenAuth(token)
// 					)
// 				}
// 			).then((json: Enfant) => {
// 				if (json) {
// 					setEnfant(json)
// 					resolve(undefined);
// 				} else {
// 					setError(json);
// 					resolve(processApiErrors(json));
// 				}
// 			})
// 			.catch((err) => {
// 				setError(err);
// 				resolve(processApiErrors(err));
// 			});
// 		});



// export const enfantList = (coloApiUrl: string, token: string): Promise<Enfant[]> => 
// 	send(
// 		coloApiUrl + 'enfants',
// 		{
// 			method: 'GET',
// 			...headers(
// 				acceptJson(),
// 				tokenAuth(token)
// 			)
// 		}
// 	);


// export const checkEmail = (coloApiUrl: string, email: string) =>
// 	send(
// 		coloApiUrl + 'check-mail/' + email,
// 		{
// 			method: 'GET',
// 			...headers(
// 				acceptJson()
// 			)
// 		}
// 	);

// export const authenticate = (coloApiUrl: string, rootUrl: string, email: string) => 
// 	send(
// 		coloApiUrl + 'authenticate',
// 		{
// 			method: 'POST',
// 			body: JSON.stringify({email, path: rootUrl + '?set-token='}),
// 			...headers(
// 				contentTypeJson(),
// 				acceptJson()
// 			)
// 		}
// 	);

// export const deleteInscription = (coloApiUrl: string, token: string, enfant: Enfant): Promise<any> => 
// 	send(
// 		coloApiUrl + 'enfants/' + enfant.id,
// 		{
// 			method: 'DELETE',
// 			...headers(
// 				acceptJson(),
// 				tokenAuth(token)
// 			)
// 		}
// 	);

// export const updateVoyage = (coloApiUrl: string, token: string, id: number, voyage: Voyage): Promise<Sejour> => 
// 	send(
// 		coloApiUrl + 'sejours/' + id + '/update',
// 		{
// 			method: 'POST',
// 			body: JSON.stringify(voyage),
// 			...headers(
// 				contentTypeJson(),
// 				acceptJson(),
// 				tokenAuth(token)
// 			)
// 		}
// 	);


// export const deleteSejour = (coloApiUrl: string, token: string, id: number): Promise<APIResponse<Enfant, 'enfant'>> =>
// 	send(
// 		coloApiUrl + 'sejours/' + id,
// 		{
// 			method: 'DELETE',
// 			...headers(
// 				acceptJson(),
// 				tokenAuth(token)
// 			)
// 		}
// 	)



// const updateResponsable = (coloApiUrl: string, token: string, responsable: Responsable): Promise<APIResponse<Responsable, 'responsable'>> => 
// 	send(
// 		coloApiUrl + 'responsables/' + responsable.id,
// 		{
// 			method: 'PUT',
// 			body: JSON.stringify({...responsable, adresse: undefined}),
// 			...headers(
// 				acceptJson(),
// 				contentTypeJson(),
// 				tokenAuth(token)
// 			)
// 		}
// 	)

// export const updateReponsables = (coloApiUrl: string, token: string, responsables: {responsable1: Responsable, responsable2?: Responsable}, refreshData: () => void, setError: (error: any) => void): Promise<ApiErrors | undefined> => {
// 	return new Promise((resolve) => {
// 		updateResponsable(coloApiUrl, token, responsables.responsable1)
// 		.then(json => {
// 			if (json) {
// 				if (responsables.responsable2) {
// 					return updateResponsable(coloApiUrl, token, responsables.responsable2)
// 						.then(json => {
// 							if (json) {
// 								refreshData();
// 								resolve(undefined);
// 							} else {
// 								setError(json);
// 								resolve(processApiErrors(json));
// 							}
// 							return;
// 						});
// 				}
				
// 				refreshData();
// 				resolve(undefined);
// 			} else {
// 				setError(json);
// 				resolve(processApiErrors(json));
// 			}
// 			return;
// 		})
// 		.catch((err) => {
// 			setError(err);
// 			resolve(processApiErrors(err));
// 		});
// 	});
// }


// export const updateAddresse = (coloApiUrl: string, token: string, adresse: Adresse, refreshData: () => void, setError: (error: any) => void): Promise<ApiErrors | undefined> => {
// 	return new Promise((resolve) => {
// 		send(
// 			coloApiUrl + 'adresses/' + adresse.id,
// 			{
// 				method: 'PUT',
// 				body: JSON.stringify(adresse),
// 				...headers(
// 					acceptJson(),
// 					contentTypeJson(),
// 					tokenAuth(token)
// 				)
// 			}
// 		)
// 		.then(json => {
// 			if (json) {
// 				refreshData();
// 				resolve(undefined);
// 			} else {
// 				setError(json);
// 				resolve(processApiErrors(json));
// 			}
// 			return;
// 		})
// 		.catch((err) => {
// 			setError(err);
// 			resolve(processApiErrors(err));
// 		});
// 	});
// }

// documents
export interface DocumentRefs {
	[name: string]: string
}

export const getSejourDocuments = (coloResourcesUrl: string, token: string, id: number): Promise<DocumentRefs> =>
	send(
		coloResourcesUrl + 'listfiles.php?s=' + id + '&token=' + token,
		{
			method: 'GET',
			...headers(acceptJson())
		}
	)