import send, { acceptJson, contentTypeJson, headers, tokenAuth } from '../utils/Http';
import { ApiErrors, APIResponse, processApiErrors } from './commons';

export interface Adresse {
	id: number,
	ligne1: string
	ligne2?: string,
	ville: string,
	codePostal: string,
	pays: string
}

export enum Sexe {
	MALE = 'MALE',
	FEMALE = 'FEMALE'
}

export interface Responsable {
	id: number,
	nom: string,
	prenom: string,
	sexe: Sexe,
	email: string,
	numeroTelephone: string
	adresse: Adresse
}



export const checkEmail = (coloApiUrl: string, email: string) =>
	send(
		coloApiUrl + 'check-mail/' + email,
		{
			method: 'GET',
			...headers(
				acceptJson()
			)
		}
	);

export const authenticate = (coloApiUrl: string, rootUrl: string, email: string) => 
	send(
		coloApiUrl + 'authenticate',
		{
			method: 'POST',
			body: JSON.stringify({email, path: rootUrl + '?set-token='}),
			...headers(
				contentTypeJson(),
				acceptJson()
			)
		}
	);

export const getProfile = (coloApiUrl: string, token: string): Promise<Responsable> => 
	send(coloApiUrl + 'profile', { method: 'GET', ...headers(tokenAuth(token), acceptJson()) }).then(res => res.profile)


const updateResponsable = (coloApiUrl: string, token: string, responsable: Responsable): Promise<APIResponse<Responsable, 'responsable'>> => 
	send(
		coloApiUrl + 'responsables/' + responsable.id,
		{
			method: 'PUT',
			body: JSON.stringify({...responsable, adresse: undefined}),
			...headers(
				acceptJson(),
				contentTypeJson(),
				tokenAuth(token)
			)
		}
	)

export const updateReponsables = (coloApiUrl: string, token: string, responsables: {responsable1: Responsable, responsable2?: Responsable}, refreshData: () => void, setError: (error: any) => void): Promise<ApiErrors | undefined> => {
	return new Promise((resolve) => {
		updateResponsable(coloApiUrl, token, responsables.responsable1)
		.then(json => {
			if (json) {
				if (responsables.responsable2) {
					return updateResponsable(coloApiUrl, token, responsables.responsable2)
						.then(json => {
							if (json) {
								refreshData();
								resolve(undefined);
							} else {
								setError(json);
								resolve(processApiErrors(json));
							}
							return;
						});
				}
				
				refreshData();
				resolve(undefined);
			} else {
				setError(json);
				resolve(processApiErrors(json));
			}
			return;
		})
		.catch((err) => {
			setError(err);
			resolve(processApiErrors(err));
		});
	});
}


export const updateAddresse = (coloApiUrl: string, token: string, adresse: Adresse, refreshData: () => void, setError: (error: any) => void): Promise<ApiErrors | undefined> => {
	return new Promise((resolve) => {
		send(
			coloApiUrl + 'adresses/' + adresse.id,
			{
				method: 'PUT',
				body: JSON.stringify(adresse),
				...headers(
					acceptJson(),
					contentTypeJson(),
					tokenAuth(token)
				)
			}
		)
		.then(json => {
			if (json) {
				refreshData();
				resolve(undefined);
			} else {
				setError(json);
				resolve(processApiErrors(json));
			}
			return;
		})
		.catch((err) => {
			setError(err);
			resolve(processApiErrors(err));
		});
	});
}