import React, { useContext } from 'react';
import { Field, useField } from 'react-final-form';
import PageContext from './PageContext';

const formatMsg = (msg: string | undefined) => msg && msg.length > 'valid:'.length && msg.substring(0, 'valid:'.length) == 'valid:' ? ['valid', msg.substring('valid:'.length)] : ['invalid', msg];

interface TextFieldProps {
	name: string
	label: string
	colMd?: string
	helperText?: string
	required?: boolean
	[rest: string]: any
}

export default ({name, label, colMd, helperText, required = true, ...rest}: TextFieldProps) => {
	const {meta: {touched, error, submitError, validating}} = useField(name, {subscription: {touched: true, error: true, submitError: true, validating: true}});
	const context = useContext(PageContext);
	
	const hasError = touched && error || submitError;
	const [msgClass, msg] = formatMsg(error || submitError);
	return (
		<div className={'form-group col-md-' + (colMd || 6)}>
			<label htmlFor={name + '-field'} className={'col-form-label pl-3 pr-3 mb-2 ' + context.labelClass}>{label}</label>
			<Field
				className={'form-control' + (hasError ? ' is-' + msgClass : '')}
				required={required}
				name={name}
				id={name + '-field'}
				type="text"
				component="input"
				{...rest}
			/>
			{validating && <div className="spinner-border" style={{position: 'absolute', top: '53px', right: '11px', height: '1.5rem', width: '1.5rem', borderWidth: '0.15rem'}} role="status"><span className="sr-only">Loading...</span></div>}
			{hasError && <div className={msgClass + '-feedback'}>{msg}</div> || helperText && <small className={'form-text ' + (context.textColor || 'text-muted')}>{helperText}</small>}
		</div>
	);
}