import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { addSejours, Enfant } from '../../api';
import { EnfantParam } from '../../utils';
import { SessionContext } from '../SessionContext';
import SelectionSejourWizard from '../Wizards/SelectionSejourWizard';


export default ({match, history}: RouteComponentProps<EnfantParam>) => {
    const context = useContext(SessionContext);
	const childId = parseInt(match.params.enfantId);
	const onSubmit = addSejours(
		context.coloApiUrl,
		context.token,
		childId,
		(enfant: Enfant) => {
            context.setEnfant(enfant);
            history.push(context.rootUrl + '/enfants/' + enfant.id)
        },
		context.addError
	)

	return (
		<div className="container">
			<SelectionSejourWizard enfantId={childId} onSubmit={onSubmit} />
		</div>
	)
};