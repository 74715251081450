import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ChildWizard from '../Wizards/ChildWizard';
import { addEnfant, Enfant } from '../../api';
import { SessionContext } from '../SessionContext';

export default ({history}: RouteComponentProps) => {
    const context = useContext(SessionContext);
    
    const onSubmit = addEnfant(
        context.coloApiUrl,
        context.token,
        (enfant: Enfant) => {
            context.setEnfant(enfant);
            history.push(context.rootUrl)
        },
        context.addError
    );

    return <ChildWizard onSubmit={onSubmit} />
}