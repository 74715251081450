import React from 'react';
import { WizardPage } from '../../../utils/Wizard';
import { Title } from '../Components/WizardComponents';
import { AdressePart } from './AdressesWizardPage';
import { ResponsablePart } from './ResponsablesWizardPage';


export const ResponsableWizardPage = ({name, values}: {name: string, values?: any, condition: (values: any) => boolean}) => {

	return (
		<WizardPage>
			<Title>DEUXIEME RESPONSABLE LEGAL</Title>

			<div className="form-row">
				<div className="form-group col-md-6">
					<ResponsablePart name={name} />
				</div>

				{values.situation == 'SEPARE' && <div className="form-group col-md-6">
					<AdressePart name="responsable2.adresse" />
				</div>}
			</div>
		</WizardPage>
	);
}