import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { Enfant, updateEnfant } from '../../../api';
import { SessionContext } from '../../SessionContext';
import TextField from '../../Wizards/Components/TextField';

export const Remarques = ({enfant}: {enfant: Enfant}) => {
	const context = useContext(SessionContext);

	const onSubmit = updateEnfant(
        context.coloApiUrl,
		context.token,
		enfant.id,
        (rt: Enfant) => {
            context.setEnfant({...rt, sejours: enfant.sejours});
        },
        context.addError
    );

	return (
		<div className="mt-4 mb-7">
			<h5>Remarques</h5>
			<Form onSubmit={onSubmit} initialValues={{remarques: enfant.remarques}}>
				{form => (
					<form onSubmit={form.handleSubmit} autoComplete="off">
						<div className="form-row">
							<TextField label="Remarque(s)" name="remarques" colMd="12" component="textarea" required={false} />
						</div>
						
						<div className="d-flex align-items-center">
							{!form.pristine && <button className="btn btn-primary" type="submit" disabled={form.invalid || form.submitting || form.validating}>Sauvegarder</button>}
							{/* <span className="pl-2 text-danger">{error}</span> */}
							{form.submitting && <div className="position-relative pl-2" style={{height: '2rem'}}>
								<div className="spinner-border text-primary position-absolute" role="status">
									<span className="sr-only">Chargement...</span>
								</div>
							</div>}
						</div>
					</form>
				)}
			</Form>
		</div>
	)
}