import React, { useContext } from 'react';
import TextField from './TextField';
import memoize from 'memoize-one';
import { checkEmail, authenticate } from '../../../api';
import { SessionContext } from '../../SessionContext';


export default ({label, name, helperText, required}: {label: string, name: string, helperText: string, required?: boolean}) => {
	const context = useContext(SessionContext);
	
	const validateEmail = memoize(
		async (email: string) => {
			if (email) {
				if (!/^[^@]+@[^@.]+\.[^@.]+$/.test(email))
					return 'Format email invalide';

				const resultCheckMail = await checkEmail(context.coloApiUrl, email);
				if (resultCheckMail.exists === false)
					return null; // Email non existant
				
				await authenticate(context.coloApiUrl, context.rootUrl, email);
				return 'valid:Un email a été envoyé. Consultez vos email pour continuer...';	
			} else {
				return null;
			}
		});
	const validateEmailRequired = (email: string) => email ? validateEmail(email) : 'Valeur obligatoire pour continuer';
	
	
	return (
		<div className="form-row">
			<TextField label={label} name={name} colMd="6" helperText={helperText} validate={(typeof(required) == 'undefined' || required ? validateEmailRequired : validateEmail)} required={required} />
		</div>
	);
}