import React, { useCallback, useState } from "react";
import { authenticate } from "../../api";

export const LoginPage = ({coloApiUrl, rootUrl}: {coloApiUrl: string, rootUrl: string}) => {
	const [loading, setLoading] = useState<'NONE' | 'CALL API' | 'EMAIL SENT'>('NONE');
	const [error, setError] = useState<string | undefined>();

	const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(ev => {
		ev.preventDefault();
		setLoading('CALL API');
		setError(undefined);
		authenticate(coloApiUrl, rootUrl, ev.currentTarget['email'].value)
			.then(() => setLoading('EMAIL SENT'))
			.catch(() => {
				setError('Email incorrect');
				setLoading('NONE');
			})
	}, []);
	
	
	return (
		<div className="bg-grey-light py-4">
			<h2 className="text-center">Connexion</h2>
			<div className="container">
				<form onSubmit={onSubmit} className="row justify-content-md-center">
					<div className="col-md-6">
						<div className="form-group">
							<label htmlFor="email-field" className="col-form-label pl-3 pr-3 mb-2 text-white bg-green">Email</label>
							<input className={'form-control' + (error ? ' is-invalid' : loading == 'EMAIL SENT' ? ' is-valid' : '')} required name="email" id="email-field" type="text" onChange={() => setLoading('NONE')}	/>
							{loading == 'CALL API' && <div className="spinner-border" style={{position: 'absolute', top: '53px', right: '22px', height: '1.5rem', width: '1.5rem', borderWidth: '0.15rem'}} role="status"><span className="sr-only">Loading...</span></div>}
							{error && <small className={'form-text invalid-feedback'} style={{fontSize: '120%'}}>{error}</small> || //helperText && 
							<small className={'form-text' + (loading == 'EMAIL SENT' ? ' valid-feedback' : ' text-muted')} style={{fontSize: '120%'}}>{loading == 'EMAIL SENT' ? 'Un email vous a été envoyé avec un lien pour continuer' : 'Entrez votre email pour continuer'}</small>}
						</div>
						<div className="buttons text-center d-flex justify-content-center align-items-center">
							<button className="btn btn-primary m-1" disabled={loading != 'NONE'}>Valider</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}