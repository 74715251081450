import React from 'react';
import Session from './Session';

interface IInscriptionPartProps {
	/**
	 * @default https://api.timothee.fr/colo/
	 */
	apiUrl?: string;

	/**
	 * @default https://timothee.fr/colo-ressources/
	 */
	resourcesUrl?: string;

	/**
	 * @default ''
	 */
	rootUrl?: string;

	/**
	 * @default false
	 */
	debugMode?: boolean;
}

export default ({apiUrl = 'https://api.timothee.fr/colo/', resourcesUrl = 'https://timothee.fr/colo-ressources/', rootUrl = '', debugMode = false}: IInscriptionPartProps) => 
	<Session coloApiUrl={apiUrl} resourcesUrl={resourcesUrl} rootUrl={rootUrl} debugMode={!!debugMode} />
