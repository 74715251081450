import React from 'react';
import { WizardPage } from '../../../utils/Wizard';
import { Title } from '../Components/WizardComponents';
import ChildFields from '../Components/ChildFields';

export default () => (
	<WizardPage>
		<Title>ENFANT</Title>

		<ChildFields />
	</WizardPage>
);
