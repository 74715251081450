import React, { useEffect } from 'react';

export const Message = ({children, title, buttons}: {children: React.ReactNode, title: React.ReactNode, buttons: React.ReactNode}) => {
	useEffect(() => {
		const previsousOverflow = document.body.style.overflow;
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = previsousOverflow;
		}
	})

	return (
		<div className="fixed-top fixed-bottom bg-white d-flex flex-column justify-content-center align-items-center">
			<h2 className="m-0 font-weight-normal text-center text-muted">{title}</h2>
			<div className="m-4 text-center">{children}</div>
			<div className="mt-4">{buttons}</div>
		</div>
	)
}